import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  ReferenceField,
  SimpleForm,
  SelectField,
  FunctionField,
  DateField,
  NumberField,
} from 'react-admin';
import {
  OdocReferenceField,
} from '../common/fields';
import {
  HospitalReviewFilter
} from '../common/filters';

export const HospitalReviewList = props => (
  <List {...props} sort={{ field: 'reviewDate', order: 'DESC' }} filters={<HospitalReviewFilter />}>
    <Datagrid rowClick="show">
      <TextField label="아이디" source="id" />
      <OdocReferenceField
        sortable={false}
        idSource="id"
        nameSource="name"
        label="병원"
        source="hospital.id"
        reference="Hospital"
        linkType="show"
      />
      <NumberField
        label="점수"
        source="rating"
      />
      <TextField label="내용" source="content" />
      <TextField label="참조" source="referenceDescription" />
      <DateField label="리뷰등록일" source="reviewDate" />
    </Datagrid>
  </List>
);

export const HospitalReviewShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="아이디" source="id" />
      <OdocReferenceField
        sortable={false}
        idSource="id"
        nameSource="name"
        label="병원"
        source="hospital.id"
        reference="Hospital"
        linkType="show"
      />
      <NumberField
        label="점수"
        source="rating"
      />
      <TextField label="내용" source="content" />
      <TextField label="참조" source="referenceDescription" />
      <DateField label="리뷰등록일" source="reviewDate" />
    </SimpleShowLayout>
  </Show>
);

