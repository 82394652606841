import React from 'react';
import {
  List,
  Datagrid,
  TextField,
} from 'react-admin';
import { OdocDateField } from '../common/fields';

export const LogList = props => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }}>
    <Datagrid>
      <OdocDateField source="createdAt" />
      <TextField source="message" />
    </Datagrid>
  </List>
);
