import React from 'react';
import { TextInput } from 'react-admin';

const HospitalDutyHourInput = ({ label, source }) => {
  return (
    <div style={{ margin: '10px 0' }}>
      <div>{label}</div>
      <span>
        <TextInput label="시작" source={`${source}s`} placeholder="9:00" /> - <TextInput label="종료" source={`${source}c`} placeholder="16:00" />
      </span>
    </div>
  );
}

export default HospitalDutyHourInput;
