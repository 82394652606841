import React, { Component, useState } from 'react';

import { change } from 'redux-form';
import {
  TabbedForm,
  FormTab,
  TextInput,
  NumberInput,
  required,
  ArrayInput,
  SimpleFormIterator,
  LongTextInput,
  ImageInput,
  ImageField,
  SelectInput,
  REDUX_FORM_NAME,
  SelectArrayInput,
} from 'react-admin';
import styled from 'styled-components';
import GoogleMapEx from '../../images/googlemapex.png';
import HospitalDutyHourInput from './HospitalDutyHourInput';
import { Query } from 'react-apollo';
import { locationSiQuery, locationGunguQuery } from '../../graphql';
import { FormDataConsumer } from 'ra-core';
import {
  hospitalBasicTagChoices,
  hospitalBasicInfoChoices,
} from '../common/choices';
import HospitalEditToolbar from './HospitalEditToolbar';

const Desc = styled.div`
  margin: 30px 0 0;
  & > div {
    padding: 20px 0;
  }
  ul {
    list-style: decimal;
  }
`;

const MapEx = styled.img`
  width: 970px;
  height: auto;
`;

const LatLngHelp = () => (
  <Desc>
    <div>
      * 위도, 경도 찾기
      <br />
      (사이트 :{' '}
      <a href="https://www.google.co.kr/maps/">
        <u>https://www.google.co.kr/maps/</u>
      </a>
      )
    </div>
    <div>
      <ul>
        <li>google map에서 주소나 병원명으로 검색한다.</li>
        <li>주소창에서 파란색으로 블록된부분을 찾는다.</li>
        <li>위도,경도(ex: 37.5042955,127.0228719)를 넣는다.</li>
      </ul>
    </div>
    <div>
      <MapEx src={GoogleMapEx} alt="lat, lng" />
    </div>
  </Desc>
);

const validateHospitalCreation = values => {
  const errors = {};
  if (values.tags && values.tags.length > 3) {
    errors.tags = '3개까지 선택 가능합니다';
  }

  return errors;
};

const HospitalEditForm = ({record, ...rest}) => {
  const [values, setValues] = useState({});
  let newRecord = {...record};

  if(record.tags && record.tags.length > 0) {
    const tags = record.tags.map((tag) => tag.tagName);
    newRecord.tags = tags;
  }

  if(record.infos && record.infos.length > 0) {
    const infos = record.infos.map((info) => info.infoText);
    newRecord.infos = infos;
  }

  if(record.locationString) {
    const locationSi = record.locationString.split(' ', 1);
    if (locationSi && locationSi.length > 0) {
      newRecord.locationSiName = locationSi[0];
      const locationGungu = record.locationString.replace(newRecord.locationSiName + ' ', '');
      newRecord.locationGunguName = locationGungu;
    }    
  }

  if(record.photos && record.photos.length > 0) {
    
    const mainPhotos = [];
    const lifePhotos = [];
    const casebookPhotos = [];

    record.photos.map(item => {
      if (item.category === 'main') {
        mainPhotos.push({
          photo: {
            src: item.url,
            url: item.url,
          },
          title: item.title,
          description: item.description
        });
      }
      if (item.category === 'life') {
        lifePhotos.push({
          photo: {
            src: item.url,
            url: item.url,
          },
          title: item.title,
          description: item.description
        });
      }
      if (item.category === 'casebook') {
        casebookPhotos.push({
          photo: {
            src: item.url,
            url: item.url,
          },
          title: item.title,
          description: item.description
        });
      }

      return item;
    })

    newRecord = {
      mainPhotos,
      lifePhotos,
      casebookPhotos,
      ...newRecord
    }
  }

  return (
    <TabbedForm
      record={newRecord}
      {...rest}
      redirect="show"
      validate={(values) => {
        setValues(values);
        return validateHospitalCreation(values);
      }}
      toolbar={<HospitalEditToolbar values={values} />}
    >
      <FormTab label="기본정보">
        <TextInput label="병원명" source="name" validate={required()} />
        <TextInput label="주소" source="address" validate={required()} />
        <TextInput
          label="전화번호"
          source="phone"
          validate={required()}
          placeholder="02-123-4567"
        />

        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => {
            return (
              <React.Fragment>
                <Query query={locationSiQuery}>
                  {({ loading, error, data }) => {
                    if (!loading && data && data.locationSi) {
                      const choices = data.locationSi.map(si => ({
                        id: si.name,
                        name: si.name,
                      }));

                      return (
                        <SelectInput
                          label="시/도"
                          source="locationSiName"
                          validate={required()}
                          choices={choices}
                          onChange={value => {
                            dispatch(
                              change(
                                REDUX_FORM_NAME,
                                'locationGunguName',
                                null,
                              ),
                            );
                          }}
                        />
                      );
                    }
                    return null;
                  }}
                </Query>
                <Query
                  query={locationGunguQuery}
                  variables={{ siName: formData.locationSiName }}
                  skip={!formData.locationSiName}
                >
                  {({ loading, error, data }) => {
                    if (!loading && data && data.locationGungu) {
                      const choices = data.locationGungu.map(gun => ({
                        id: gun.name,
                        name: gun.name,
                      }));

                      return (
                        <SelectInput
                          label="군/구"
                          source="locationGunguName"
                          validate={required()}
                          choices={choices}
                        />
                      );
                    }
                    return (
                      <SelectInput
                        label="군/구"
                        source="locationGunguName"
                        validate={required()}
                        choices={[]}
                      />
                    );
                  }}
                </Query>
              </React.Fragment>
            );
          }}
        </FormDataConsumer>
        <NumberInput label="위도" source="lat" validate={required()} />
        <NumberInput label="경도" source="lng" validate={required()} />

        <LatLngHelp />
      </FormTab>
      <FormTab label="담당자 정보">
        <ArrayInput label="담당자" source="hospitalPeople">
          <SimpleFormIterator>
            <TextInput label="이름" source="name" />
            <TextInput
              label="연락처"
              source="phonenum"
              placeholder="01012345678"
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="상세정보">
        <LongTextInput label="한줄소개" source="metadata.intro" />
        <LongTextInput label="오닥터 코멘트" source="metadata.onelineComment" />
        {/* <RichTextInput label="자세한 설명" source="metadata.intro" /> */}
        <div style={{ width: '100%', marginTop: '20px' }}>
          <div>
            <b>진료시간</b>
          </div>
          <HospitalDutyHourInput
            label="월요일"
            source="metadata.openhour.dutyTime1"
          />
          <HospitalDutyHourInput
            label="화요일"
            source="metadata.openhour.dutyTime2"
          />
          <HospitalDutyHourInput
            label="수요일"
            source="metadata.openhour.dutyTime3"
          />
          <HospitalDutyHourInput
            label="목요일"
            source="metadata.openhour.dutyTime4"
          />
          <HospitalDutyHourInput
            label="금요일"
            source="metadata.openhour.dutyTime5"
          />
          <HospitalDutyHourInput
            label="토요일"
            source="metadata.openhour.dutyTime6"
          />
          <HospitalDutyHourInput
            label="일요일"
            source="metadata.openhour.dutyTime7"
          />
          <HospitalDutyHourInput
            label="점심시간"
            source="metadata.openhour.lunchTime"
          />
        </div>
        <SelectArrayInput
          label="특성선택"
          source="tags"
          choices={hospitalBasicTagChoices}
        />
        <SelectArrayInput
          label="추가정보"
          source="infos"
          choices={hospitalBasicInfoChoices}
        />
      </FormTab>
      <FormTab label="사진">
        <ArrayInput label="대표사진" source="mainPhotos">
          <SimpleFormIterator>
            <ImageInput label="사진" source="photo" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
            <TextInput label="제목" source="title" />
            <LongTextInput label="코멘트" source="description" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput label="LIFE & EPISODE" source="lifePhotos">
          <SimpleFormIterator>
            <ImageInput label="사진" source="photo" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
            <TextInput label="제목" source="title" />
            <LongTextInput label="코멘트" source="description" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput label="Before & After" source="casebookPhotos">
          <SimpleFormIterator>
            <ImageInput label="사진" source="photo" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
            <TextInput label="제목" source="title" />
            <LongTextInput label="코멘트" source="description" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      {/* <FormTab label="수가표"> */}
      {/* 상담설정 */}
      {/* 수가표 */}
      {/* </FormTab> */}
    </TabbedForm>
  );
}

export default HospitalEditForm;
