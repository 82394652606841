import React, { useState } from 'react';
import {
  SelectInput,
} from 'react-admin';
import _ from 'lodash';
import { change } from 'redux-form';
import { huserStateChoices, huserRoleChoices } from './choices';
import { REDUX_FORM_NAME } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { Typography, InputLabel } from '@material-ui/core';
import { FieldTitle } from 'ra-core';
import { record } from 'apollo-boost';

export const HuserStateInput = (props) => (
  <SelectInput {...props} choices={huserStateChoices} />
);

export const HuserRoleInput = (props) => (
  <SelectInput {...props} choices={huserRoleChoices} />
);

export const StringArrayInput = (props) => {
  const { label, dispatch, source, record } = props;
  const initArray = _.get(record, source);
  const [array, setArray] = useState(initArray || []);
  
  return (
    <div>
      <div>
        <InputLabel htmlFor={source} shrink>
          <FieldTitle
            label={label}
          />
        </InputLabel>
      </div>
      <div>
        {array.map((member, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" gutterBottom style={{ width: '3em'}}>
              {index + 1}
            </Typography>
            <TextField
              id="standard-name"
              label={label}
              style={{ width: '100%' }}
              value={member}
              onChange={({target}) => {
                let newArray = [...array];
                newArray[index] = target.value;
                setArray(newArray);
                dispatch(
                  change(REDUX_FORM_NAME, `${source}`, newArray)
                );
              }} 
              margin="normal"
            />
            <Button size="small" onClick={() => {
              const newArray = [...array];
              newArray.splice(index, 1)
              setArray(newArray);
              dispatch(
                change(REDUX_FORM_NAME, `${source}`, newArray)
              );
            }}>
              <RemoveCircleOutlineIcon style={{ marginRight: '8px' }} /> REMOVE
            </Button>
          </div>
        ))}
      </div>
      <Button size="small" onClick={() => {
        setArray([...array, ""]);
        dispatch(
          change(REDUX_FORM_NAME, `${source}`, [...array, ""])
        );
      }}>
        <AddCircleOutlineIcon style={{ marginRight: '8px' }} /> ADD
      </Button>
    </div>
  );
}