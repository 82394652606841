import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Edit,
  SimpleForm,
} from 'react-admin';
import { UserFilter } from '../common/filters';
import { HuserStateField, HuserRoleField, OdocDateField, OdocReferenceField } from '../common/fields';
import { HuserStateInput, HuserRoleInput } from '../common/inputs';

export const HuserList = props => (
  <List title="파트너" {...props} filters={<UserFilter />} sort={{ field: 'createdAt', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField label="아이디" source="id" />
      <TextField label="이름" source="username" />
      <TextField label="이메일" source="email" />
      <TextField label="핸드폰" source="phonenum" />
      <OdocReferenceField sortable={false} idSource="id" nameSource="name" label="병원" source="hospital.id" reference="Hospital" linkType="show" />
      <HuserRoleField label="권한" source="role" />
      <HuserStateField label="인증상태" source="status" />
      <OdocDateField label="가입일" source="createdAt" />
    </Datagrid>
  </List>
);

export const HuserShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="아이디" source="id" />
      <TextField label="이름" source="username" />
      <TextField label="이메일" source="email" />
      <TextField label="핸드폰" source="phonenum" />
      <OdocReferenceField idSource="id" nameSource="name" label="병원" source="hospital.id" reference="Hospital" linkType="show" />
      <HuserRoleField label="권한" source="role" />
      <HuserStateField label="인증상태" source="status" />>
      <OdocDateField label="가입일" source="createdAt" />
    </SimpleShowLayout>
  </Show>
);

export const HuserEdit = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm redirect="show">
      <TextField label="아이디" source="id" />
      <TextField label="이름" source="username" />
      <TextField label="이메일" source="email" />
      <TextField label="핸드폰" source="phonenum" />
      <OdocReferenceField idSource="id" nameSource="name" label="병원" source="hospital.id" reference="Hospital" linkType="show" />
      <HuserRoleInput label="권한" source="role" />
      <HuserStateInput label="인증상태" source="status" />
      <OdocDateField label="가입일" source="createdAt" />
    </SimpleForm>
  </Edit>
);
