import React from 'react';
import { connect } from 'react-redux';
import { MenuItemLink, getResources, Responsive } from 'react-admin';
import { withRouter } from 'react-router-dom';

const Menu = ({ classes, resources, onMenuClick, logout }) => (
  <div>
    {resources.map((resource) => (
      <MenuItemLink
        classes={classes}
        key={resource.name}
        to={`/${resource.name}`}
        primaryText={
          resource?.options?.label ? resource.options.label : resource.name
        }
        onClick={onMenuClick}
      />
    ))}
    <MenuItemLink
      classes={classes}
      to='/alarmlist'
      primaryText='알람 대상'
      onClick={onMenuClick}
    />
    <MenuItemLink
      classes={classes}
      to='/createreivews'
      primaryText='리뷰 생성'
      onClick={onMenuClick}
    />

    <Responsive
      small={logout}
      medium={null} // Pass null to render nothing on larger devices
    />
  </div>
);

const mapStateToProps = (state) => ({
  resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(Menu));
