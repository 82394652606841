import React from 'react';
import { TextField } from 'react-admin';

const HospitalDutyHourField = ({ label, source, ...rest }) => {
  return (
    <div style={{ margin: '10px 0', width: '100%' }}>
      <TextField style={{ display: 'inline' }} label="시작" source={`${source}s`} {...rest} /> - <TextField style={{ display: 'inline' }} label="종료" source={`${source}c`} {...rest} />
    </div>
  );
}

HospitalDutyHourField.defaultProps = { addLabel: true };

export default HospitalDutyHourField;
