import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  ReferenceField,
  ArrayField,
  ImageField,
  UrlField,
  Create,
  Edit,
} from 'react-admin';
import { DoctorMetadataField } from '../common/fields';
import { DoctorFilter } from '../common/filters';
import DoctorCreateForm from '../Doctor/DoctorCreateForm';
import DoctorEditForm from '../Doctor/DoctorEditForm';

export const DoctorList = props => (
  <List {...props} filters={<DoctorFilter />} sort={{ field: 'createdAt', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField label="아이디" source="id" />
      <TextField label="이름" source="name" />
      <ReferenceField sortable={false} label="소속병원" source="hospital.id" reference="Hospital" linkType="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="전문분야" source="speciality" />
      <TextField label="소개" source="metadata.oneline_profile" sortable={false} />
      <DoctorMetadataField label="약력" source="metadata" sortable={false} />
    </Datagrid>
  </List>
);

export const DoctorShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="아이디" source="id" />
      <TextField label="이름" source="name" />
      <ReferenceField label="소속병원" source="hospital.id" reference="Hospital" linkType="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="전문분야" source="speciality" />
      <TextField label="소개" source="metadata.oneline_profile" />
      <DoctorMetadataField label="약력" source="metadata" />
      <ArrayField label="사진" source="photos">
        <Datagrid>
          <ImageField label="사진" source="url" />
        </Datagrid>
      </ArrayField>
      <ArrayField label="동영상" source="videos">
        <Datagrid>
          <ImageField label="썸네일" source="thumbnail" />
          <TextField label="제목" source="title" />
          <TextField label="설명" source="description" />
          <UrlField label="영상링크" source="url" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export const DoctorCreate = props => (
  <Create {...props}>
    <DoctorCreateForm {...props} />
  </Create>
);

export const DoctorEdit = props => (
  <Edit {...props}>
    <DoctorEditForm {...props} />
  </Edit>
);

