import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import axios from 'axios';
import { graphql, compose } from 'react-apollo';
import { addHospitalMutation, signS3Mutation } from '../../graphql';
import formatFilename from '../../utils/fileUpload';

class HospitalCreateToolbar extends Component {

  uploadToS3 = async (file, signedRequest) => {
    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };
    const resp = await axios.put(signedRequest, file, options);
    return resp;
  };

  handleSubmit = async () => {
    const { name, address, phone, locationSiName, locationGunguName, lat, lng, hospitalPeople, metadata, tags, infos, mainPhotos, lifePhotos, casebookPhotos } = this.props.values;
    
    // 필수입력항목
    if (!name || !address || !phone || !locationSiName || !locationGunguName || !lat || !lng) {
      return;
    }

    const variables = {
      name,
      address,
      phone,
      locationSiName,
      locationGunguName,
      lat,
      lng,
    };

    if (hospitalPeople && hospitalPeople.length > 0) {
      variables.hospitalPeople = hospitalPeople;
    }

    if (metadata) {
      variables.metadata = metadata;
    }

    if (tags && tags.length > 0) {
      variables.tags = tags;
    }
    if (infos && infos.length > 0) {
      variables.infos = infos;
    }

    let uploadedMainPhotos = [];
    if (mainPhotos && mainPhotos.length > 0) {
      uploadedMainPhotos = [...mainPhotos];
      // s3에 사진 업로드 
      await Promise.all(
        mainPhotos.map(async ({ photo, title, description }, index) => {
          const res = await this.props.signS3({
            variables: {
              filename: formatFilename(photo.title),
              filetype: photo.rawFile.type,
            },
          });

          const { signedRequest, url } = res.data.signS3;

          try {
            await this.uploadToS3(photo.rawFile, signedRequest);
            uploadedMainPhotos[index] = {
              url,
              category: 'main',
              title,
              description,
            };
            
          } catch (e) {
            console.log('Image Upload Failed!');
          }
        }),
      );
    }

    let uploadedLifePhotos = [];
    if (lifePhotos && lifePhotos.length > 0) {
      // s3에 사진 업로드 
      uploadedLifePhotos = [...lifePhotos];
      await Promise.all(
        lifePhotos.map(async ({ photo }, index) => {
          const res = await this.props.signS3({
            variables: {
              filename: formatFilename(photo.title),
              filetype: photo.rawFile.type,
            },
          });

          const { signedRequest, url } = res.data.signS3;

          try {
            await this.uploadToS3(photo.rawFile, signedRequest);
            uploadedLifePhotos[index] = {
              url,
              category: 'life',
              title: photo.title,
              description: photo.description
            };
            
          } catch (e) {
            console.log('Image Upload Failed!');
          }
        }),
      );
    }

    let uploadedCasebookPhotos = [];
    if (casebookPhotos && casebookPhotos.length > 0) {
      // s3에 사진 업로드 
      uploadedCasebookPhotos = [...casebookPhotos];
      await Promise.all(
        casebookPhotos.map(async ({ photo, title, description }, index) => {
          const res = await this.props.signS3({
            variables: {
              filename: formatFilename(photo.title),
              filetype: photo.rawFile.type,
            },
          });

          const { signedRequest, url } = res.data.signS3;

          try {
            await this.uploadToS3(photo.rawFile, signedRequest);
            uploadedCasebookPhotos[index] = {
              url,
              category: 'casebook',
              title,
              description,
            };
            
          } catch (e) {
            console.log('Image Upload Failed!');
          }
        }),
      );
    }

    const photos = uploadedMainPhotos.concat(uploadedLifePhotos, uploadedCasebookPhotos);
    variables.photos = photos;

    const addRes = await this.props.addHospital({ variables });
    this.props.history.push('/Hospital');
  }

  render() {
    return (
      <div style={{ padding: '24px', marginTop: '15px' }}>
        <Button variant="contained" color="primary" type="button" onClick={this.handleSubmit} >
          SAVE
        </Button>
      </div>
    );
  }
}

export default compose(
  graphql(addHospitalMutation, {
    name: 'addHospital',
  }),
  graphql(signS3Mutation, { name: 'signS3' }),
)(withRouter(HospitalCreateToolbar));

