import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  RichTextField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  ArrayField,
} from 'react-admin';
import { CardActions, Button } from '@material-ui/core';

import { UserReqFilter } from '../common/filters';
import { ReqTypeField, ReqStateField, GenderField, AgeGroupField, OdocDateField, StringListField, PresenceField, DirectUserReqField, TherapyItemField, ReqPhotoField, LocationStringField} from '../common/fields';

export const UserReqsList = props => (
  <List {...props} filters={<UserReqFilter />} sort={{ field: 'createdAt', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField label="아이디" source="id" />
      <ReferenceField sortable={false} label="유저" source="user.id" reference="User">
        <TextField source="username" />
      </ReferenceField>
      <DirectUserReqField label="상담구분" source="directReq" />
      <ReqTypeField label="치료구분" source="reqType" />
      <OdocDateField label="상담신청일" source="createdAt" />
      <ReqStateField label="상담상태" source="status" />
      <StringListField sortable={false} label="고려사항" source="considerTags" />
      <RichTextField sortable={false} label="자세한상담" source="desc" />
      <GenderField label="성별" source="gender" />
      <AgeGroupField label="나이대" source="ageGroup" />
      <LocationStringField label="희망지역" source="locations" sortable={false}/>
      <TextField label="답변갯수" source="hosResps.length" sortable={false} />
      <TextField label="티어" source="chargeTier" sortable={false} />
    </Datagrid>
  </List>
);

const UserReqShowActions = ({ basePath, data, resource, id, history, ...rest }) => (
  <CardActions>
    <Button color="primary" onClick={() => history.replace(`/HosResp/create/?reqId=${id}`)}>답변추가</Button>
  </CardActions>
);

export const UserReqShow = props => (
  <Show actions={<UserReqShowActions {...props} />} {...props}>
    <SimpleShowLayout>
      <TextField label="아이디" source="id" />
      <ReferenceField label="유저" source="user.id" reference="User">
        <TextField source="username" />
      </ReferenceField>
      <ReqPhotoField {...props} label="상담 사진" source="txImageUrls" />
      <DirectUserReqField label="상담구분" source="directReq" />
      <ReqTypeField label="치료구분" source="reqType" />
      <OdocDateField label="상담신청일" source="createdAt" />
      <ReqStateField label="상담상태" source="status" />
      <StringListField label="고려사항" source="considerTags" />
      <LocationStringField label="희망지역" source="locations"/>
      <RichTextField label="자세한상담" source="desc" />
      <GenderField label="성별" source="gender" />
      <AgeGroupField label="나이대" source="ageGroup" />
      <TextField label="티어" source="chargeTier" sortable={false} />
      <ArrayField label="치과치료 항목" source="txItems">
        <Datagrid>
          <TherapyItemField label="치료" source="itemcode" />
          <TextField label="갯수" source="unit" />
        </Datagrid>
      </ArrayField>
      <ArrayField label="교정치료 항목" source="orthoItems">
        <Datagrid>
          <TherapyItemField label="치료" source="itemcode" />
          <TextField label="부분" source="part" />
        </Datagrid>
      </ArrayField>
      <ArrayField label="받은 답변" source="hosResps">
        <Datagrid>
          <TextField label="아이디" source="id" />
          <ReferenceField label="답변병원" source="hospitalId" reference="Hospital" linkType="show">
            <TextField source="name" />
          </ReferenceField>
          <TextField label="치과예상치료비(만원)" source="txMaxPrice" />
          <TextField label="교정예상치료비(만원)" source="orthoMaxPrice" />
          <TextField label="치료답변" source="treatmentAnswer" />
          <TextField label="증상답변" source="symptomAnswer" />
          <PresenceField sortable={false} label="조회여부" source="checkedAns" />
          <OdocDateField sortable={false} label="답변조회일" source="checkedAns.createdAt" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);