import React from 'react';

export const Gender = ({ gender, ...rest }) => {
  switch (gender) {
    case 'male': return (<span {...rest}>남성</span>);
    case 'female': return (<span {...rest}>여성</span>);
    default: return null;
  }
};

export const Age = ({ age, ...rest }) => {
  switch (age) {
    case 'less_teens': return (<span {...rest}>10대 이하</span>);
    case 'twenties': return (<span {...rest}>20대</span>);
    case 'thirties': return (<span {...rest}>30대</span>);
    case 'forties': return (<span {...rest}>40대</span>);
    case 'fifties': return (<span {...rest}>50대</span>);
    case 'over_sixties': return (<span {...rest}>60대 이상</span>);
    default: return null;
  }
};

export default {};
