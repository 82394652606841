export const genderChoices = [
  { id: 'male', name: '남성' },
  { id: 'female', name: '여성' },
];

export const reviewStatusChoices = [
  { id: 'waiting', name: '검수대기' },
  { id: 'confirmed', name: '승인(노출)' },
  { id: 'hidden', name: '승인(숨김)' },
  { id: 'reject_general', name: '반려(일반)' },
  { id: 'reject_complaint', name: '반려(불만)' },
];

export const reviewScoreChoices = [
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
  { id: 4, name: 4 },
  { id: 5, name: 5 },
];

export const reviewTherapyTypeChoices = [
  { id: 'implant', name: '임플란트'},
  { id: 'cavity', name: '충치치료'},
  { id: 'aesthetic', name: '미용, 심미치료'},
  { id: 'ortho', name: '치아교정'},
];

export const huserStateChoices = [
  { id: 'confirmed', name: '인증완료' },
  { id: 'unconfirmed', name: '미인증' },
];

export const huserRoleChoices = [
  { id: 'admin', name: '어드민' },
  { id: 'unclassified', name: '미분류' },
  // { id: 'paydoc', name: '페이닥터' },
];

export const reqStateChoices = [
  { id: 'published', name: '진행중' },
  { id: 'terminated', name: '종료됨' },
];

export const ageGroupChoices = [
  { id: 'less_teens', name: '10대 이하' },
  { id: 'twenties', name: '20대' },
  { id: 'thirties', name: '30대' },
  { id: 'forties', name: '40대' },
  { id: 'fifties', name: '50대' },
  { id: 'over_sixties', name: '60대 이상' },
];

export const reservationStateChoices = [
  { id: 'visited', name: '내원' },
  { id: 'finished', name: '치료완료' },
  { id: 'reserved', name: '예약접수' },
  { id: 'notrespond', name: '부재중' },
  { id: 'duplicated', name: '중복' },
  { id: 'canceled', name: '예약취소' },
  { id: 'willvisit', name: '내원예정' },
  { id: 'invalidphone', name: '결번' },
  { id: 'phonecall', name: '전화예약' },
];

export const accountHistoryDirectionChoices = [
  { id: 'in', name: '충전' },
  { id: 'out', name: '소진' },
];

export const accountHistoryMethodInputChoices = [
  { name: '계좌이체', direction: "in", id: 'charge_account' },
  { name: '서비스 적립', direction: "in", id: 'charge_free' },
  { name: '기타', direction: "in", id: 'refund_etc' },
  { name: '기타', direction: "out", id: 'withdraw_etc' },
];

export const accountHistoryMethodFieldChoices = [
  { name: '계좌이체', id: 'charge_account' },
  { name: '칭찬 적립', id: 'charge_compliment' },
  { name: '서비스 적립', id: 'charge_free' },
  { name: '환불: 결번', id: 'refund_invalidphone' },
  { name: '환불: 중복', id: 'refund_duplicated' },
  { name: '기타', id: 'refund_etc' },
  { name: '상담답변 과금', id: 'withdraw_counsel' },
  { name: '기타', id: 'withdraw_etc' },
];

export const checkedAnsCallStateChoices = [
  { id: 'needcontact', name: '연락필요' },
  { id: 'notanswer', name: '부재중' },
  { id: 'etc', name: '기타' },
  { id: 'reservedvisit', name: '내원예정' },
  { id: 'cancelled', name: '취소' },
  { id: 'duplicated', name: '중복' },
  { id: 'invalidphone', name: '결번' },
];


export const hospitalBasicTagChoices = [
  { id: '최신 시설 및 설비', name: '최신 시설 및 설비' },
  { id: '자세한 설명과 친절함', name: '자세한 설명과 친절함' },
  { id: '편안하고 안아픈 진료', name: '편안하고 안아픈 진료' },
  { id: '치료분야별 주치의', name: '치료분야별 주치의' },
  { id: '합리적 가격', name: '합리적 가격' },
];

export const hospitalBasicInfoChoices = [
  { id: '전문의', name: '전문의' },
  { id: '야간진료', name: '야간진료' },
  { id: '주말진료', name: '주말진료' },
  { id: '주차가능', name: '주차가능' },
  { id: '무통마취', name: '무통마취' },
  { id: '기공실 구비', name: '기공실 구비' },
  { id: '소아전문', name: '소아전문' },
];

export default {};
