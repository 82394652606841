import React, { Component } from 'react'
import {
  Toolbar,
} from 'react-admin';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import formatFilename from '../../utils/fileUpload';
import { graphql, compose } from 'react-apollo';
import { signS3Mutation, addDoctorMutaiton } from '../../graphql';

class DoctorCreateToolbar extends Component {

  uploadToS3 = async (file, signedRequest) => {
    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };
    const resp = await axios.put(signedRequest, file, options);
    return resp;
  };

  handleSubmit = async () => {
    const { values } = {...this.props};
    
    try {
      if (!values || !values.name || !values.hospitalId) {
        return;
      }

      const { name, hospitalId, speciality, metadata, photo, videos } = values;
      
      let variables = {
        name: name,
        hospitalId: hospitalId,
      };

      // 사진 업로드
      if (photo) {
        // s3에 사진 업로드 
        const res = await this.props.signS3({
          variables: {
            filename: formatFilename(photo.title),
            filetype: photo.rawFile.type,
          },
        });

        const { signedRequest, url } = res.data.signS3;

        try {
          await this.uploadToS3(photo.rawFile, signedRequest);
          variables.photo = url;
          
        } catch (e) {
          console.log('Image Upload Failed!');
        }
      }

      // 동영상 프리뷰 업로드
      let videoList = [];
      if (videos && videos.length > 0) {
        videoList = [...videos]
        // s3에 사진 업로드
        await Promise.all(
          videos.map(async ({ thumbnail }, index) => {
            const res = await this.props.signS3({
              variables: {
                filename: formatFilename(thumbnail.title),
                filetype: thumbnail.rawFile.type,
              },
            });
  
            const { signedRequest, url } = res.data.signS3;
  
            try {
              await this.uploadToS3(thumbnail.rawFile, signedRequest);
              videoList[index].thumbnail = url;
              videoList[index] = {
                ...videos[index],
                thumbnail: url,
              };
            } catch (e) {
              console.log('Image Upload Failed!');
            }
          }),
        );

        variables.videos = videoList;
      }

      if (speciality) {
        variables.speciality = speciality;
      }

      if (metadata && (metadata.oneline_profile || (metadata.brief_history && metadata.brief_history.length > 0))) {
        variables.metadata = metadata;
      }

      const addRes = await this.props.addDoctor({ variables });

      this.props.history.push('/Doctor');
    } catch (e) {
      console.log(e);
    }

    
  }
  render() {
    return (
      <Toolbar {...this.props} >
        <button type="button" onClick={this.handleSubmit}>SAVE</button>
      </Toolbar>
    )
  }
}

export default compose(
  graphql(signS3Mutation, { name: 'signS3' }),
  graphql(addDoctorMutaiton, { name: 'addDoctor' }),
)(withRouter(DoctorCreateToolbar));
