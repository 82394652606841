import gql from 'graphql-tag';

export const allHospitalsQuery = gql`
  query(
    $perPage: Int
    $currentPage: Int
    $latStart: Float
    $latEnd: Float
    $lngStart: Float
    $lngEnd: Float
    $filter: JSON
    $sort: AdminSort
  ) {
    allHospitalsForAdmin(
      perPage: $perPage
      currentPage: $currentPage
      latStart: $latStart
      latEnd: $latEnd
      lngStart: $lngStart
      lngEnd: $lngEnd
      filter: $filter
      sort: $sort
    ) {
      data {
        id
        address
        name
        phone
        lat
        lng
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const allUsersQuery = gql`
  query($perPage: Int, $currentPage: Int, $filter: JSON, $sort: AdminSort) {
    allUsersForAdmin(
      perPage: $perPage
      currentPage: $currentPage
      filter: $filter
      sort: $sort
    ) {
      data {
        id
        username
        email
        phonenum
        sex
        createdAt
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const allHusersQuery = gql`
  query($perPage: Int, $currentPage: Int, $filter: JSON, $sort: AdminSort) {
    allHusersForAdmin(
      perPage: $perPage
      currentPage: $currentPage
      filter: $filter
      sort: $sort
    ) {
      data {
        createdAt
        updatedAt
        id
        username
        email
        phonenum
        hospital {
          id
          name
        }
        role
        status
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const allLogsQuery = gql`
  query($perPage: Int, $currentPage: Int, $filter: JSON, $sort: AdminSort) {
    allLogsForAdmin(
      perPage: $perPage
      currentPage: $currentPage
      filter: $filter
      sort: $sort
    ) {
      data {
        createdAt
        message
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const allComplimentsQuery = gql`
  query($perPage: Int, $currentPage: Int, $filter: JSON, $sort: AdminSort) {
    allComplimentsForAdmin(
      perPage: $perPage
      currentPage: $currentPage
      filter: $filter
      sort: $sort
    ) {
      data {
        id
        desc
        phonenum
        toName
        toHosName
        fromHuser {
          id
          username
          hospital {
            id
            name
          }
        }
        toHuser {
          id
          username
        }
        status
        createdAt
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const allUserReqsQuery = gql`
  query($perPage: Int, $currentPage: Int, $filter: JSON, $sort: AdminSort) {
    allUserReqsForAdmin(
      perPage: $perPage
      currentPage: $currentPage
      filter: $filter
      sort: $sort
    ) {
      data {
        id
        reqType
        createdAt
        txItems {
          itemcode
          unit
        }
        orthoItems {
          itemcode
          part
        }
        user {
          id
          username
        }
        status
        txMaxPrice
        orthoMaxPrice
        txImageUrls
        orthoImageUrls
        orthoWanna
        alarmOn
        considerTags
        gender
        ageGroup
        desc
        locations {
          id
          siName
          gunguName
        }
        chargeTier
        hosResps {
          id
          checkedAns {
            id
          }
        }
        directReq {
          id
        }
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const getHosRespQuery = gql`
  query($id: ID!) {
    getHosRespForAdmin(id: $id) {
      id
      ureqId
      hospitalId
      user {
        id
        username
      }
      hospital {
        id
        name
      }
      userRequest {
        id
        reqType
        createdAt
        txItems {
          itemcode
          unit
        }
        orthoItems {
          itemcode
          part
        }
        user {
          id
          username
        }
        status
        txMaxPrice
        orthoMaxPrice
        txImageUrls
        orthoImageUrls
        orthoWanna
        alarmOn
        considerTags
        gender
        ageGroup
        desc
        locations {
          id
          siName
          gunguName
        }
        chargeTier
        directReq {
          id
        }
      }
      checkedAns {
        id
        createdAt
        callState
      }
      txMaxPrice
      orthoMaxPrice
      txPrices {
        itemcode
        price
        description
      }
      orthoPrices {
        itemcode
        price
        part
        description
      }
      treatmentAnswer
      symptomAnswer
      metadata
      createdAt
    }
  }
`;

export const allHosRespsQuery = gql`
  query($perPage: Int, $currentPage: Int, $filter: JSON, $sort: AdminSort) {
    allHosRespsForAdmin(
      perPage: $perPage
      currentPage: $currentPage
      filter: $filter
      sort: $sort
    ) {
      data {
        id
        ureqId
        hospitalId
        user {
          id
          username
        }
        hospital {
          id
          name
        }
        userRequest {
          id
          directReq {
            id
          }
          user {
            id
          }
        }
        checkedAns {
          id
          createdAt
          callState
        }
        txMaxPrice
        orthoMaxPrice
        txPrices {
          itemcode
          price
          description
        }
        orthoPrices {
          itemcode
          price
          part
          description
        }
        treatmentAnswer
        symptomAnswer
        metadata
        createdAt
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const allReservationsQuery = gql`
  query($perPage: Int, $currentPage: Int, $filter: JSON, $sort: AdminSort) {
    allReservationsForAdmin(
      perPage: $perPage
      currentPage: $currentPage
      filter: $filter
      sort: $sort
    ) {
      data {
        id
        ureqId
        hospitalId
        state
        createdAt
        userReqs {
          id
          user {
            id
            username
          }
        }
        hosResps {
          id
        }
        hospitalInfo {
          id
          name
        }
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const allDoctorsQuery = gql`
  query($perPage: Int, $currentPage: Int, $filter: JSON, $sort: AdminSort) {
    allDoctorsForAdmin(
      perPage: $perPage
      currentPage: $currentPage
      filter: $filter
      sort: $sort
    ) {
      data {
        id
        name
        speciality
        metadata
        photos {
          id
          url
          category
        }
        videos {
          id
          url
          thumbnail
          title
          description
        }
        hospital {
          id
          name
        }
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const getDoctorQuery = gql`
  query($id: ID!) {
    getDoctorForAdmin(id: $id) {
      id
      name
      speciality
      metadata
      photos {
        id
        url
        category
      }
      videos {
        id
        url
        thumbnail
        title
        description
      }
      hospital {
        id
        name
      }
    }
  }
`;

export const getReservationQuery = gql`
  query($id: ID!) {
    getReservationForAdmin(id: $id) {
      id
      ureqId
      hospitalId
      state
      createdAt
      metadata
      userReqs {
        id
        chargeLevel
        user {
          id
          username
        }
      }
      hosResps {
        id
      }
      hospitalInfo {
        id
        name
      }
    }
  }
`;

export const getHuserQuery = gql`
  query($id: ID!) {
    getHuserForAdmin(id: $id) {
      createdAt
      updatedAt
      id
      username
      email
      phonenum
      hospital {
        id
        name
      }
      role
      status
    }
  }
`;

export const getUserReqQuery = gql`
  query($id: ID!) {
    getUserReqForAdmin(id: $id) {
      id
      reqType
      createdAt
      txItems {
        itemcode
        unit
      }
      orthoItems {
        itemcode
        part
      }
      user {
        id
        username
      }
      status
      txMaxPrice
      orthoMaxPrice
      txImageUrls
      orthoImageUrls
      orthoWanna
      alarmOn
      considerTags
      gender
      ageGroup
      desc
      locations {
        id
        siName
        gunguName
      }
      chargeTier
      hosResps {
        id
        hospitalId
        hospital {
          name
        }
        txMaxPrice
        orthoMaxPrice
        treatmentAnswer
        symptomAnswer
        metadata
        checkedAns {
          id
          createdAt
        }
      }
      directReq {
        id
      }
    }
  }
`;

export const getUserQuery = gql`
  query($id: ID!) {
    getUserForAdmin(id: $id) {
      id
      username
      email
      phonenum
      sex
      createdAt
      userReqs {
        id
        reqType
        createdAt
        txItems {
          itemcode
          unit
        }
        orthoItems {
          itemcode
          part
        }
        user {
          id
          username
        }
        status
        txMaxPrice
        orthoMaxPrice
        txImageUrls
        orthoImageUrls
        orthoWanna
        alarmOn
        considerTags
        gender
        ageGroup
        desc
        hosResps {
          id
          hospitalId
          hospital {
            name
          }
          txMaxPrice
          orthoMaxPrice
          treatmentAnswer
          symptomAnswer
          metadata
        }
        directReq {
          id
        }
      }
    }
  }
`;

export const getBalanceQuery = gql`
  query($hospitalId: ID!) {
    getBalanceForAdmin(hospitalId: $hospitalId) {
      balanceTotal
    }
  }
`;

export const getHospitalQuery = gql`
  query($id: ID!) {
    getHospitalForAdmin(id: $id) {
      id
      address
      name
      phone
      lat
      lng
      metadata
      locationString
      photos {
        id
        url
        category
        title
        description
      }
      tags {
        id
        tagName
      }
      infos {
        id
        infoText
      }
      hospitalPeople {
        name
        phonenum
      }
      compliments {
        id
        desc
        phonenum
        toName
        toHosName
        fromHuser {
          id
          username
          hospital {
            id
            name
          }
        }
        toHuser {
          id
          username
        }
        status
        createdAt
      }
      doctors {
        id
        name
        speciality
        metadata
        photos {
          url
          category
        }
        videos {
          url
          thumbnail
          title
          description
        }
      }
    }
  }
`;

export const allUserReviewsQuery = gql`
  query($perPage: Int, $currentPage: Int, $filter: JSON, $sort: AdminSort) {
    allUserReviewsForAdmin(
      perPage: $perPage
      currentPage: $currentPage
      filter: $filter
      sort: $sort
    ) {
      pagination {
        total
      }
      data {
        id
        desc
        status
        jsondata
        createdAt
        updatedAt
        score1
        doctor {
          id
          name
        }
        user {
          id
          username
        }
        hospital {
          id
          name
        }
        reservation {
          id
        }
      }
    }
  }
`;

export const getUserReviewQuery = gql`
  query($id: ID!) {
    getUserReviewForAdmin(id: $id) {
      id
      desc
      status
      jsondata
      createdAt
      updatedAt
      score1
      score2
      score3
      score4
      score5
      hospital {
        id
        name
      }
      doctor {
        id
        name
      }
      user {
        id
        username
      }
      reservation {
        id
      }
    }
  }
`;

export const allAccountHistorysQuery = gql`
  query($perPage: Int, $currentPage: Int, $filter: JSON, $sort: AdminSort) {
    allAccountHistorysForAdmin(
      perPage: $perPage
      currentPage: $currentPage
      filter: $filter
      sort: $sort
    ) {
      pagination {
        total
      }
      data {
        id
        direction
        desc
        amount
        method
        cumSum
        createdAt
        hosResps {
          id
          userRequest {
            id
          }
          checkedAns {
            id
            callState
            createdAt
          }
        }
        hospital {
          id
          name
        }
      }
    }
  }
`;

export const addHospitalMutation = gql`
  mutation(
    $name: String!
    $address: String!
    $phone: String!
    $lat: Float!
    $lng: Float!
    $locationSiName: String!
    $locationGunguName: String!
    $hospitalPeople: [HospitalPeopleInput!]
    $metadata: JSON
    $tags: [String!]
    $infos: [String!]
    $photos: [HospitalPhotoInput!]
  ) {
    addHospitalForAdmin(
      name: $name
      address: $address
      phone: $phone
      lat: $lat
      lng: $lng
      locationSiName: $locationSiName
      locationGunguName: $locationGunguName
      hospitalPeople: $hospitalPeople
      metadata: $metadata
      tags: $tags
      infos: $infos
      photos: $photos
    ) {
      id
      address
      name
      phone
      lat
      lng
    }
  }
`;

export const addAccountHistoryMutation = gql`
  mutation(
    $hospitalId: ID!
    $method: String!
    $caId: ID
    $amount: Float!
    $desc: String
    $direction: String!
  ) {
    addAccountHistoryForAdmin(
      hospitalId: $hospitalId
      method: $method
      caId: $caId
      amount: $amount
      desc: $desc
      direction: $direction
    ) {
      id
      amount
      cumSum
      desc
      direction
      createdAt
    }
  }
`;

export const updateHospitalMutation = gql`
  mutation($id: ID!, $data: JSON!) {
    updateHospitalForAdmin(id: $id, data: $data) {
      id
      address
      name
      phone
      lat
      lng
    }
  }
`;

export const updateDoctorMutation = gql`
  mutation($id: ID!, $data: JSON!) {
    updateDoctorForAdmin(id: $id, data: $data) {
      id
      name
      speciality
      metadata
      photos {
        id
        url
        category
      }
      videos {
        id
        url
        thumbnail
        title
        description
      }
      hospital {
        id
        name
      }
    }
  }
`;

export const updateHuserMutation = gql`
  mutation($id: ID!, $data: JSON!) {
    updateHuserForAdmin(id: $id, data: $data) {
      id
      username
      email
      phonenum
      hospital {
        id
        name
      }
      role
      status
      createdAt
      updatedAt
    }
  }
`;

export const updateUserReviewMutation = gql`
  mutation($id: ID!, $data: JSON!) {
    updateUserReviewForAdmin(id: $id, data: $data) {
      id
      desc
      status
      jsondata
      createdAt
      updatedAt
      score1
      score2
      score3
      score4
      score5
      hospital {
        id
        name
      }
      doctor {
        id
        name
      }
      user {
        id
        username
      }
      reservation {
        id
      }
    }
  }
`;

export const updateHosRespMutation = gql`
  mutation($id: ID!, $data: JSON!) {
    updateHosRespForAdmin(id: $id, data: $data) {
      id
      ureqId
      hospitalId
      user {
        id
        username
      }
      hospital {
        id
        name
      }
      userRequest {
        id
        reqType
        createdAt
        txItems {
          itemcode
          unit
        }
        orthoItems {
          itemcode
          part
        }
        user {
          id
          username
        }
        status
        txMaxPrice
        orthoMaxPrice
        txImageUrls
        orthoImageUrls
        orthoWanna
        alarmOn
        considerTags
        gender
        ageGroup
        desc
        locations {
          id
          siName
          gunguName
        }
        chargeTier
        directReq {
          id
        }
      }
      checkedAns {
        id
        createdAt
        callState
      }
      txMaxPrice
      orthoMaxPrice
      txPrices {
        itemcode
        price
        description
      }
      orthoPrices {
        itemcode
        price
        part
        description
      }
      treatmentAnswer
      symptomAnswer
      metadata
      createdAt
    }
  }
`;

export const loginAdminMutation = gql`
  mutation($id: String!, $password: String!) {
    loginAdmin(id: $id, password: $password) {
      ok
      token
      errors {
        path
        message
      }
    }
  }
`;
export const confirmHuserMutation = gql`
  mutation($id: ID!) {
    confirmHuser(id: $id) {
      id
      username
      email
      phonenum
      hospital {
        id
        name
      }
      role
      status
      updatedAt
    }
  }
`;

export const refundAccountFiatMutation = gql`
  mutation($id: ID!) {
    refundAccountFiat(id: $id) {
      id
    }
  }
`;

export const signS3Mutation = gql`
  mutation($filename: String!, $filetype: String!) {
    signS3(filename: $filename, filetype: $filetype) {
      signedRequest
      url
    }
  }
`;

export const addDoctorMutaiton = gql`
  mutation(
    $name: String!
    $hospitalId: ID!
    $speciality: String
    $metadata: JSON
    $photo: String
    $videos: [DoctorVideoInput]
  ) {
    addDoctorForAdmin(
      name: $name
      hospitalId: $hospitalId
      speciality: $speciality
      metadata: $metadata
      photo: $photo
      videos: $videos
    ) {
      id
      name
      speciality
      metadata
      photos {
        id
        url
        category
      }
      videos {
        id
        url
        thumbnail
        title
        description
      }
      hospital {
        id
        name
      }
    }
  }
`;

export const locationSiQuery = gql`
  query {
    locationSi {
      code
      name
    }
  }
`;

export const locationGunguQuery = gql`
  query($siName: String!) {
    locationGungu(siName: $siName) {
      id
      code
      name
      siCode
    }
  }
`;

export const terminateUserForAdminMutation = gql`
  mutation($id: ID!) {
    terminateUserForAdmin(id: $id)
  }
`;

export const getDiscountSettingQuery = gql`
  query($id: ID!) {
    getDiscountSettingForAdmin(id: $id) {
      id
      discountRatio
      hospital {
        id
        name
      }
    }
  }
`;

export const addDiscountSettingMutaiton = gql`
  mutation($hospitalId: ID!, $discountRatio: Int!) {
    addDiscountSettingForAdmin(
      hospitalId: $hospitalId
      discountRatio: $discountRatio
    ) {
      discountRatio
    }
  }
`;

export const updateDiscountSettingMutation = gql`
  mutation($id: ID!, $data: JSON!) {
    updateDiscountSettingForAdmin(id: $id, data: $data) {
      discountRatio
    }
  }
`;

export const allDiscountSettingsQuery = gql`
  query($perPage: Int, $currentPage: Int, $filter: JSON, $sort: AdminSort) {
    allDiscountSettingsForAdmin(
      perPage: $perPage
      currentPage: $currentPage
      filter: $filter
      sort: $sort
    ) {
      data {
        id
        discountRatio
        hospital {
          id
          name
        }
      }
      pagination {
        total
      }
    }
  }
`;

export const addHosRespMutation = gql`
  mutation(
    $hospitalId: ID!
    $ureqId: ID!
    $txMinPrice: Float
    $txMaxPrice: Float
    $orthoMinPrice: Float
    $orthoMaxPrice: Float
    $txPrices: [TxPriceInput]
    $orthoPrices: [OrthoPriceInput]
    $treatmentAnswer: String
    $symptomAnswer: String
    $metadata: JSON
  ) {
    addHosRespForAdmin(
      hospitalId: $hospitalId
      ureqId: $ureqId
      txMinPrice: $txMinPrice
      txMaxPrice: $txMaxPrice
      orthoMinPrice: $orthoMinPrice
      orthoMaxPrice: $orthoMaxPrice
      txPrices: $txPrices
      orthoPrices: $orthoPrices
      treatmentAnswer: $treatmentAnswer
      symptomAnswer: $symptomAnswer
      metadata: $metadata
    ) {
      id
    }
  }
`;

export const allHospitalReviewsQuery = gql`
  query($perPage: Int, $currentPage: Int, $filter: JSON, $sort: AdminSort) {
    allHospitalReviewsForAdmin(
      perPage: $perPage
      currentPage: $currentPage
      filter: $filter
      sort: $sort
    ) {
      data {
        id
        hospital {
          id
          name
        }
        reviewerName
        rating
        content
        reviewDate
        referenceDescription
        createdAt
        updatedAt
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const getHospitalReviewQuery = gql`
  query($id: ID!) {
    getHospitalReviewForAdmin(id: $id) {
      id
      hospital {
        id
        name
      }
      reviewerName
      rating
      content
      reviewDate
      referenceDescription
      createdAt
      updatedAt
    }
  }
`;

export const idk = {};

export const allReqCasesQuery = gql`
  query(
    $perPage: Int
    $currentPage: Int
    $filter: ReqCaseListFilterForAdmin
    $sort: AdminSort
  ) {
    allReqCasesForAdmin(
      perPage: $perPage
      currentPage: $currentPage
      filter: $filter
      sort: $sort
    ) {
      data {
        id
        title
        content
        reqDate
        userGender
        userAgeGroup
        casePhotos {
          id
          url
        }
        categories {
          id
          name
          description
        }
        resps {
          id
          hospital {
            id
          }
        }
        location {
          id
          siName
          gunguName
        }
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const getReqCaseQuery = gql`
  query($id: ID!) {
    getReqCaseForAdmin(id: $id) {
      id
      title
      content
      reqDate
      userGender
      userAgeGroup
      casePhotos {
        id
        url
      }
      categories {
        id
        name
        description
      }
      resps {
        id
        hospital {
          id
        }
      }
      location {
        id
        siName
        gunguName
      }
      createdAt
      updatedAt
    }
  }
`;

export const addReqCasesMutation = gql`
  mutation($data: [ReqCaseInput!]!) {
    addReqCasesForAdmin(data: $data) {
      id
      title
      content
      reqDate
      userGender
      userAgeGroup
      casePhotos {
        id
        url
      }
      categories {
        id
        name
        description
      }
      resps {
        id
        hospital {
          id
        }
      }
      location {
        id
        siName
        gunguName
      }
      createdAt
      updatedAt
    }
  }
`;

export const getAlarmPhoneListQuery = gql`
  query {
    getAlarmPhoneListForAdmin {
      recipientNo
      hospitalName
      huser
      hpeople
    }
  }
`;

export const addHospitalReviewsMutation = gql`
  mutation($data: [HospitalReviewInput!]!) {
    addHospitalReviewsForAdmin(data: $data) {
      id
      hospital {
        id
        name
      }
      reviewerName
      rating
      content
      reviewDate
      referenceDescription
      createdAt
      updatedAt
    }
  }
`;
