import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import { addDiscountSettingMutaiton } from '../../graphql';

class DiscountSettingCreateToolbar extends Component {
  handleSubmit = async () => {
    const { hospitalId, discountRatio } = this.props.values;
    try {
      if (!hospitalId || !discountRatio) {
        return;
      }

      const ratio = parseInt(discountRatio)
      console.log(ratio)
      if (!ratio || 0 > ratio || ratio > 100) {
        alert('잘못된 할인율 값이 입력되었습니다.')
        return;
      }

      await this.props.createDiscountSetting({
        variables: {
          hospitalId,
          discountRatio: ratio
        }
      });
      alert('할인율 설정이 추가되었습니다.');
      this.props.history.push('/DiscountSetting');
    } catch (e) {
      alert(e.message.replace('GraphQL error:', ''))
    }
  }
  render() {
    return (
      <div style={{ padding: '24px', marginTop: '15px' }}>
        <Button variant="contained" color="primary" type="button" onClick={this.handleSubmit} >
          SAVE
      </Button>
      </div>
    )
  }
}

export default compose(
  graphql(addDiscountSettingMutaiton, { name: 'createDiscountSetting' }),
)(withRouter(DiscountSettingCreateToolbar));
