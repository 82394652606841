import React, { Component } from 'react'
import { Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { graphql, compose } from 'react-apollo';
import { updateDiscountSettingMutation } from '../../graphql';

class DiscountSettingEditToolbar extends Component {
  handleSubmit = async () => {
    const { values } = { ...this.props };

    try {
      const { hospitalId, discountRatio, id } = values;

      if (!discountRatio || !hospitalId) {
        return;
      }

      let variables = {
        id,
        data: {
          hospitalId,
          discountRatio,
        },
      };

      await this.props.updateDiscountSetting({ variables });
      alert('할인율이 수정 되었습니다.');
      this.props.history.push('/DiscountSetting');
    } catch (e) {
      console.log(e);
    }
  }
  render() {
    return (
      <div style={{ padding: '24px', marginTop: '15px' }}>
        <Button variant="contained" color="primary" type="button" onClick={this.handleSubmit} >
          SAVE
      </Button>
      </div>)
  }
}

export default compose(
  graphql(updateDiscountSettingMutation, { name: 'updateDiscountSetting' }),
)(withRouter(DiscountSettingEditToolbar));
