import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import _ from 'lodash';
import ImageGallery from 'react-image-gallery';
import {
  SelectField,
  ReferenceField,
  FunctionField,
  TextField,
} from 'react-admin';
import {
  reqStateChoices,
  ageGroupChoices,
  huserStateChoices,
  huserRoleChoices,
  reservationStateChoices,
  genderChoices,
} from './choices';
import { therapyInfoById } from '../../data/therapyInfo';

export const TagsField = ({ record, source }) => {
  const tags = _.get(record, source);
  return (
    <div>
      {tags &&
        tags.length > 0 &&
        tags.map((tag) => <span key={tag.id}>{tag.tagName},</span>)}
    </div>
  );
};

export const StringListField = ({ record, source }) => {
  const list = _.get(record, source);
  return (
    <div>
      {list &&
        list.length > 0 &&
        list.map((text) => <span key={text}>{text},</span>)}
    </div>
  );
};

export const InfosField = ({ record, source }) => {
  const infos = _.get(record, source);
  return (
    <div>
      {infos &&
        infos.length > 0 &&
        infos.map((info) => <span key={info.id}>{info.infoText},</span>)}
    </div>
  );
};

export const ReqTypeField = ({ record, source }) => {
  const reqType = _.get(record, source);
  if (!reqType) {
    return null;
  }

  if (reqType === 'all') {
    return <div>교정 + 치료</div>;
  }

  if (reqType === 'tx') {
    return <div>치료</div>;
  }

  if (reqType === 'ortho') {
    return <div>교정</div>;
  }

  return null;
};

export const ReqStateField = (props) => (
  <SelectField {...props} choices={reqStateChoices} />
);

export const GenderField = (props) => (
  <SelectField {...props} choices={genderChoices} />
);

export const AgeGroupField = (props) => (
  <SelectField {...props} choices={ageGroupChoices} />
);

export const HuserStateField = (props) => (
  <SelectField {...props} choices={huserStateChoices} />
);

export const HuserRoleField = (props) => (
  <SelectField {...props} choices={huserRoleChoices} />
);

export const ReservationStateField = (props) => (
  <SelectField {...props} choices={reservationStateChoices} />
);

export const OdocDateField = ({ record, source }) => {
  const data = _.get(record, source);
  return (
    <div>{data ? moment(data).format('YYYY.MM.DD') : <div>없음</div>}</div>
  );
};
export const DoctorMetadataField = ({ record, source }) => {
  const metadata = _.get(record, source);
  return (
    <ul>
      {metadata &&
        metadata.brief_history &&
        metadata.brief_history.map((history) => (
          <li key={history}>{history}</li>
        ))}
    </ul>
  );
};

export const ReservationVisitDateField = ({ record }) =>
  record.metadata ? (
    <div>
      {moment(record.metadata.willvisitDate).format('YYYY.MM.DD')}{' '}
      {record.metadata.willvisitTime}
    </div>
  ) : (
    '정보없음'
  );

export const OdocReferenceField = ({
  nameSource,
  idSource,
  record,
  ...rest
}) => {
  try {
    return (
      <ReferenceField {...rest} record={record}>
        <FunctionField
          {...rest}
          render={(record) => `${record[nameSource]}(${record[idSource]})`}
        />
      </ReferenceField>
    );
  } catch (err) {
    console.log(err);
  }
};

export const ConditionalReferenceField = ({ record, source, ...rest }) => {
  try {
    const data = _.get(record, source);
    if (data) {
      return (
        <ReferenceField record={record} source={source} {...rest}>
          <TextField source='id' {...rest} />
        </ReferenceField>
      );
    }

    return '-';
  } catch (err) {
    console.log(err);
  }
};

export const PresenceField = ({ record, source, ...rest }) => {
  try {
    const data = _.get(record, source);
    if (data) {
      return 'O';
    }

    return 'X';
  } catch (err) {
    console.log(err);
  }
};

export const DirectUserReqField = ({ record, source, ...rest }) => {
  try {
    const data = _.get(record, source);
    if (data) {
      return '다이렉트';
    }

    return '맞춤';
  } catch (err) {
    console.log(err);
  }
};

export const HospitalPhotoField = ({ record, source, ...rest }) => {
  try {
    const photos = _.get(record, source);

    const mainPhotos = photos.filter((item) => item.category === 'main');
    const lifePhotos = photos.filter((item) => item.category === 'life');
    const casebookPhotos = photos.filter(
      (item) => item.category === 'casebook',
    );

    if (
      mainPhotos.length > 0 ||
      lifePhotos.length > 0 ||
      casebookPhotos.length > 0
    ) {
      return (
        <div>
          {mainPhotos && mainPhotos.length > 0 && (
            <div>
              <div>
                <b>대표사진</b>
              </div>
              <div>
                {mainPhotos.map((photo) => (
                  <div style={{ display: 'inline-block' }}>
                    <img
                      style={{ height: '180px' }}
                      alt='main'
                      src={photo.url}
                    />
                    <div>{photo.title}</div>
                    <div>{photo.description}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div style={{ width: '100%', height: '50px' }} />
          {lifePhotos && lifePhotos.length > 0 && (
            <div>
              <div>
                <b>LIFE & EPISODE</b>
              </div>
              <div>
                {lifePhotos.map((photo) => (
                  <div style={{ display: 'inline-block' }}>
                    <img
                      style={{ height: '180px' }}
                      alt='life'
                      src={photo.url}
                    />
                    <div>{photo.title}</div>
                    <div>{photo.description}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div style={{ width: '100%', height: '50px' }} />
          {casebookPhotos && casebookPhotos.length > 0 && (
            <div>
              <div>
                <b>BEFORE & AFTER</b>
              </div>
              <div>
                {casebookPhotos.map((photo) => (
                  <div style={{ display: 'inline-block' }}>
                    <img
                      style={{ height: '180px' }}
                      alt='casebook'
                      src={photo.url}
                    />
                    <div>{photo.title}</div>
                    <div>{photo.description}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      );
    }

    return <div></div>;
  } catch (err) {
    console.log(err);
  }
};

export const TherapyItemField = ({ record, source, ...rest }) => {
  const therapy = _.get(record, source);
  return <span>{therapyInfoById[therapy]?.viewName}</span>;
};

export const ReqPhotoField = ({ record, source, ...rest }) => {
  const GalleryContainer = styled.div`
    width: 100%;
  `;
  const txPhotos = _.get(record, 'txImageUrls');
  const orthoPhotos = _.get(record, 'orthoImageUrls');
  const items = txPhotos
    .concat(orthoPhotos)
    .reduce((photos, url) => [...photos, { original: url }], []);

  const renderItem = (item) => (
    <img
      src={item.original}
      style={{
        width: '100%',
        height: '280px',
        objectFit: 'contain',
        verticalAlign: 'middle',
      }}
    />
  );

  if (items?.length > 0) {
    return (
      <GalleryContainer>
        <ImageGallery
          items={items}
          showThumbnails={false}
          showFullscreenButton={false}
          showPlayButton={false}
          renderItem={renderItem}
        />
      </GalleryContainer>
    );
  }
  return null;
};

export const LocationStringField = ({ record, source, ...rest }) => {
  const val = _.get(record, source);
  let locationString = '';
  if (!val) return '';

  if (!Array.isArray(val)) {
    locationString += `${val.siName} ${val.gunguName}`;
  } else {
    if (val && val.length > 0) {
      val.forEach((e, i) => {
        locationString += `${e.siName} ${e.gunguName}`;
        if (i !== val.length - 1) {
          locationString += ' / ';
        }
      });
    }
  }

  return <span>{locationString}</span>;
};

ReservationVisitDateField.defaultProps = { addLabel: true };
TagsField.defaultProps = { addLabel: true };
StringListField.defaultProps = { addLabel: true };
InfosField.defaultProps = { addLabel: true };
ReqTypeField.defaultProps = { addLabel: true };
ReqStateField.defaultProps = { addLabel: true };
GenderField.defaultProps = { addLabel: true };
AgeGroupField.defaultProps = { addLabel: true };
ReservationStateField.defaultProps = { addLabel: true };
HuserRoleField.defaultProps = { addLabel: true };
HuserStateField.defaultProps = { addLabel: true };
OdocDateField.defaultProps = { addLabel: true };
DoctorMetadataField.defaultProps = { addLabel: true };
OdocReferenceField.defaultProps = { addLabel: true };
ConditionalReferenceField.defaultProps = { addLabel: true };
PresenceField.defaultProps = { addLabel: true };
DirectUserReqField.defaultProps = { addLabel: true };
HospitalPhotoField.defaultProps = { addLabel: true };
TherapyItemField.defaultProps = { addLabel: true };
LocationStringField.defaultProps = { addLabel: true };

export default {};
