import React, { Component } from 'react';
import buildGraphQLProvider from 'ra-data-graphql';
import { Admin, Resource } from 'react-admin';

import { HttpLink } from 'apollo-link-http';
import { ApolloLink, concat } from 'apollo-link';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HuserList, HuserShow, HuserEdit } from './components/admin/husers';
import authProvider from './authProvider';
import buildQuery from './buildQuery';
import { UserList, UserShow } from './components/admin/users';
import { HospitalList, HospitalCreate, HospitalEdit, HospitalShow } from './components/admin/hospitals';
import { ComplimentList } from './components/admin/compliment';
import { UserReviewList, UserReviewShow, UserReviewEdit } from './components/admin/userreviews';
import { LogList } from './components/admin/logs';
import { UserReqsList, UserReqShow } from './components/admin/userreqs';
import { ReservationsList, ReservationsShow } from './components/admin/reservations';
import { HosRespList, HosRespShow, HosRespEdit, HosRespCreate } from './components/admin/hosresps';
import { DoctorList, DoctorShow, DoctorCreate, DoctorEdit } from './components/admin/doctors';
import GlobalStyle from './globalStyle';
import { AccountHistoryList, AccountHistoryCreate } from './components/admin/accounthistory';
import { ApolloProvider } from 'react-apollo';
import { DiscountSettingList, DiscountSettingCreate, DiscountSettingEdit, DiscountSettingShow } from './components/admin/discountsetting';
import { HospitalReviewList, HospitalReviewShow } from './components/admin/hospitalreviews';
import { ReqCaseList, ReqCaseShow, ReqCaseCreate } from './components/admin/reqcases';
import "react-image-gallery/styles/css/image-gallery.css";
import AppLayout from './components/AppLayout/AppLayout';
import CustomRoutes from './customRoutes/CustomRoutes';

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-odoc-admin-token': localStorage.getItem('odocAdminToken'),
    },
  }));

  return forward(operation);
});

const BACKEND_URL = process.env.REACT_APP_API_URL;
const httpLink = new HttpLink({ uri: `${BACKEND_URL}/graphql` });
const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});
class App extends Component {
  constructor() {
    super();
    this.state = { dataProvider: null };
  }

  componentDidMount() {
    buildGraphQLProvider({
      client,
      buildQuery: buildQuery,
    }).then(dataProvider => this.setState({ dataProvider }));
  }

  render() {
    const { dataProvider } = this.state;

    if (!dataProvider) {
      return <div>Loading</div>;
    }

    return (
      <ApolloProvider client={client}>
        <Admin customRoutes={CustomRoutes} appLayout={AppLayout} dataProvider={dataProvider} authProvider={authProvider}>
          <Resource name="User" options={{ label: '유저' }} list={UserList} edit={UserShow} />
          <Resource name="Huser" options={{ label: '파트너 유저' }} list={HuserList} show={HuserShow} edit={HuserEdit} />
          <Resource name="Hospital" options={{ label: '병원' }} list={HospitalList} create={HospitalCreate} show={HospitalShow} edit={HospitalEdit} />
          <Resource name="Doctor" options={{ label: '의사' }} list={DoctorList} show={DoctorShow} create={DoctorCreate} edit={DoctorEdit} />
          <Resource name="UserReq" options={{ label: '상담' }} list={UserReqsList} edit={UserReqShow} />
          <Resource name="HosResp" options={{ label: '상담 답변' }} list={HosRespList} show={HosRespShow} edit={HosRespEdit} create={HosRespCreate} />
          {/* <Resource name="Reservation" options={{ label: '예약' }} list={ReservationsList} show={ReservationsShow} />         */}
          <Resource name="Compliment" options={{ label: '칭찬' }} list={ComplimentList} />
          <Resource name="HospitalReview" options={{ label: '병원 리뷰' }} list={HospitalReviewList} show={HospitalReviewShow} />
          {/* <Resource name="UserReview" options={{ label: '유저 리뷰' }} list={UserReviewList} show={UserReviewShow} edit={UserReviewEdit} /> */}
          <Resource name="AccountHistory" options={{ label: '충전금' }} list={AccountHistoryList} create={AccountHistoryCreate} />
          {/* <Resource name="DiscountSetting" options={{ label: '할인율' }} list={DiscountSettingList} show={DiscountSettingShow} create={DiscountSettingCreate} edit={DiscountSettingEdit} /> */}
          <Resource name="ReqCase" options={{ label: '상담 사례' }} create={ReqCaseCreate} list={ReqCaseList} show={ReqCaseShow} />
          <Resource name="Log" options={{ label: '로그' }} list={LogList} />
        </Admin>
        <GlobalStyle />
      </ApolloProvider>
    );
  }
}

export default App;
