import React, { Component, useState, useEffect } from 'react';
import { readString } from 'react-papaparse';
import axios from 'axios';
import formatFilename from '../../utils/fileUpload';
import { signS3Mutation, addReqCasesMutation } from '../../graphql';
import { graphql, compose } from 'react-apollo';
import moment from 'moment';

const MAX_ROW_COUNT = 30;

const thStyle = {
  minWidth: 80,
  maxWidth: 80,
  border: '1px solid grey',
  fontSize: 14,
};

const thPhotoStyle = {
  width: 200,
  border: '1px solid grey',
  fontSize: 14,
};

const tdStyle = {
  minWidth: 80,
  maxWidth: 80,
  border: '1px solid grey',
  fontSize: 14,
};

const tdPhoto = {
  border: '1px solid grey',
  width: 200,
  maxWidth: 200,
  height: 74,
};

const imgStyle = {
  width: 64,
  height: 64,
};

const rowLength = 15;

// const input = `556	1	2	3	1	female	less_teens	2020-06-01	사례 제목 1	사례 내용 1	56	1	답변 내용 1	TRUE	TRUE
// 556	1	2	3	1	female	twenties	2020-06-02	사례 제목 2	사례 내용 2	56	1	답변 내용 2	TRUE	TRUE
// 556	2	2	3	1	female	thirties	2020-06-03	사례 제목 3	사례 내용 3	56	1	답변 내용 3	TRUE	TRUE
// 556	2	2	3	1	female	forties	2020-06-04	사례 제목 4	사례 내용 4	57	1	답변 내용 4	TRUE	TRUE
// 556	3	2	3	2	male	fifties	2020-06-05	사례 제목 5	사례 내용 5	57	3	답변 내용 5	FALSE	FALSE
// 556	3	2	3	2	male	over_sixties	2020-06-06	사례 제목 6	사례 내용 6	57	3	답변 내용 6	FALSE	FALSE
// 556	1	2	3	2	male	over_sixties	2020-06-07	사례 제목 7	사례 내용 7	58	3	답변 내용 7	FALSE	FALSE
// 556	1	2	3	2	male	over_sixties	2020-06-08	사례 제목 8	사례 내용 8	58	3	답변 내용 8	FALSE	FALSE`;

// const input =
//   '556	1	2	3	1	female	less_teens	2020-06-01	사례 제목 1	사례 내용 1	56	1	답변 내용 1	TRUE	TRUE';

const ages = [
  'less_teens',
  'twenties',
  'thirties',
  'forties',
  'fifties',
  'over_sixties',
];

const genders = ['female', 'male'];

class ReqCaseCreateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      importedFiles: [],
      prevImgs: [],
    };
  }
  componentDidMount = () => {
    // const result = readString(input);
    // console.log(result);
    // if (result.data[0].length === rowLength) {
    //   let importedFiles = [];
    //   let prevImgs = [];
    //   for (let index = 0; index < result.data.length; index++) {
    //     importedFiles.push([null, null, null]);
    //     prevImgs.push(['', '', '']);
    //   }
    //   this.setState({
    //     rows: result.data,
    //     importedFiles,
    //     prevImgs,
    //   });
    // }
  };
  uploadToS3 = async (file, signedRequest) => {
    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };
    const resp = await axios.put(signedRequest, file, options);
    return resp;
  };

  // refReqId: Int # 참조 상담 - ok
  // locationId: Int # 지역 - ok
  // title: String! # 제목 - ok
  // content: String! # 사례 내용 - ok
  // userGender: ReqCaseUserGenderEnum # 사례자 성별 - ok
  // userAgeGroup: ReqCaseUserAgeGroupEnum # 사례자 연령대 - ok
  // visible: Boolean = true # 노출 여부 - ok
  // reqDate: Date! # 사례 날짜
  // categoryIds: [ID!]! # 사례 분류 ids - ok
  // casePhotoUrls: [String!] # 사례 사진 urls
  // resps: [ReqCaseRespInput!]! # 사례 답변

  // hospitalId: ID! # 사례 답변 병원 아이디
  // content: String! # 사례 답변 내용
  // refHrId: ID # 참조한 답변
  // visible: Boolean = true # 사례에 대한 답변 노출 여부

  parseBool = (value) => {
    return value.toLowerCase() === 'true';
  };
  submitCases = async () => {
    const { rows } = this.state;
    try {
      const uploadUrls = await this.uploadImgs();
      if (rows.length !== uploadUrls.length) {
        throw Error('입력 파라미터 오류');
      }

      const data = rows.map((row, idx) => {
        const caseVisible = row[13];
        const respVisible = row[14];
        const symptomAnswer = row[12];
        const userGender = row[5];
        const userAgeGroup = row[6];
        const reqDate = row[7];

        const date = moment(reqDate, 'YYYY-MM-DD', true);
        if (!date.isValid())
          throw Error(`${idx + 1}열에 잘못된 날짜 정보가 입력되었습니다.`);

        if (
          !ages.find((e) => {
            return e === userAgeGroup;
          })
        )
          throw Error(`${idx + 1}열에 잘못된 나이 정보가 입력되었습니다.`);

        if (
          !genders.find((e) => {
            return e === userGender;
          })
        )
          throw Error(`${idx + 1}열에 잘못된 성별 정보가 입력되었습니다.`);

        let categoryIds = []
        if (row[1]) categoryIds.push(parseInt(row[1]))
        if (row[2]) categoryIds.push(parseInt(row[2]))
        if (row[3]) categoryIds.push(parseInt(row[3]))

        return {
          refReqId: parseInt(row[0]),
          locationId: parseInt(row[4]),
          title: row[8],
          content: row[9],
          userGender,
          userAgeGroup,
          visible: this.parseBool(caseVisible),
          reqDate,
          categoryIds,
          casePhotoUrls: uploadUrls[idx],
          resps: [
            {
              hospitalId: parseInt(row[11]),
              content: symptomAnswer,
              refHrId: parseInt(row[10]),
              visible: this.parseBool(respVisible),
            },
          ],
        };
      });
      const result = await this.props.addReqCases({
        variables: {
          data,
        },
      });
      console.log(result);
      alert('저장되었습니다.');
    } catch (error) {
      alert(`저장에 실패했습니다'\n${error}`);
    }
  };
  uploadImgs = async () => {
    try {
      if (this.state.importedFiles.length !== this.state.rows.length) {
        alert(
          'INVALID ARRAY LENGTH',
          this.state.importedFiles.length,
          this.state.rows.length,
        );
        return;
      }

      this.setState({
        uploading: true,
      });

      let uploadedImages = [];
      const promises = this.state.importedFiles.map(async (fileList, index) => {
        uploadedImages.push([]);
        if (fileList) {
          // s3에 사진 업로드
          const files = Object.values(fileList);
          const promises2 = files.map(async (file, index2) => {
            if (file) {
              // console.log(file);
              uploadedImages[index].push([]);

              const res = await this.props.signS3({
                variables: {
                  filename: formatFilename(file.name),
                  filetype: file.type,
                },
              });
              const { signedRequest, url } = res.data.signS3;
              try {
                await this.uploadToS3(file, signedRequest);
                uploadedImages[index][index2] = url;
              } catch (e) {
                throw e;
              }
            }
          });
          return await Promise.all(promises2);
        }
      });

      const rets = await Promise.all(promises);
      // console.log(rets);
      this.setState({
        uploading: false,
      });
      return uploadedImages;
    } catch (e) {
      console.log(e);
      alert('Image Upload Failed!', e.message);
      this.setState({
        uploading: false,
      });
    }
  };
  removeImg = (rowIndex, index) => {
    const { prevImgs, importedFiles } = this.state;
    let newPrevImgs = prevImgs;
    let newImportedFiles = importedFiles;
    newPrevImgs[rowIndex][index] = '';
    if (newImportedFiles[rowIndex][index])
      delete newImportedFiles[rowIndex][index];
    this.setState({
      prevImgs: newPrevImgs,
      importedFiles: newImportedFiles,
    });
  };
  render() {
    return (
      <div
        style={{
          backgroundColor: '#FFFFFF',
          width: '100%',
          height: '100%',
          margin: 0,
          padding: 20,
        }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <textarea
            style={{
              width: 100,
              height: 32,
              padding: 0,
              margin: 0,
            }}
            value=''
            onChange={(e) => {
              if (
                this.state.rows.length > 0 &&
                !window.confirm(
                  '기존에 작성중이던 테이블이 있습니다. 정말 덮어쓰시겠습니까?',
                )
              ) {
                return;
              }
              const result = readString(e.target.value);

              if (result.data.length > MAX_ROW_COUNT) {
                alert(`최대 ${MAX_ROW_COUNT}열만 한번에 추가할 수 있습니다.`);
                return;
              }

              if (result.data[0].length === rowLength) {
                let importedFiles = [];
                let prevImgs = [];
                for (let index = 0; index < result.data.length; index++) {
                  importedFiles.push([null, null, null]);
                  prevImgs.push(['', '', '']);
                }
                this.setState({
                  rows: result.data,
                  importedFiles,
                  prevImgs,
                });
              }
            }}></textarea>
          <button
            style={{
              width: 100,
              height: 32,
              padding: 0,
              margin: 0,
              marginLeft: 10,
            }}
            onClick={() => {
              if (
                this.state.rows.length > 0 &&
                !window.confirm(
                  '기존에 작성중이던 테이블이 있습니다. 정말 덮어쓰시겠습니까?',
                )
              ) {
                return;
              }

              this.setState({
                rows: [],
                importedFiles: [],
                prevImgs: [],
              });
            }}>
            초기화
          </button>
          <button
            disabled={this.state.uploading}
            style={{
              width: 100,
              height: 32,
              padding: 0,
              margin: 0,
              marginLeft: 10,
            }}
            onClick={this.submitCases}>
            올리기
          </button>
        </div>
        <table
          style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
          <thead>
            <tr>
              <th style={thStyle}>상담ID</th>
              <th style={thStyle}>카테고리 1(ID)</th>
              <th style={thStyle}>카테고리 2(ID)</th>
              <th style={thStyle}>카테고리 3(ID)</th>
              <th style={thStyle}>지역(ID)</th>
              <th style={thStyle}>성별</th>
              <th style={thStyle}>연령</th>
              <th style={thStyle}>날짜</th>
              <th style={thStyle}>제목</th>
              <th style={thStyle}>내용</th>
              <th style={thStyle}>답변(ID)</th>
              <th style={thStyle}>병원(ID)</th>
              <th style={thStyle}>답변내용</th>
              <th style={thStyle}>사례노출</th>
              <th style={thStyle}>답변노출</th>
              <th style={thPhotoStyle}>사진</th>
            </tr>
          </thead>
          <tbody>
            {this.state.rows.map((e, i) => {
              return (
                <tr key={i}>
                  {e.map((e2, i2) => {
                    return (
                      <td key={i2} style={tdStyle}>
                        {e2}
                      </td>
                    );
                  })}
                  <ImageImport
                    prevImgs={this.state.prevImgs}
                    removeImg={this.removeImg}
                    importedFiles={this.state.importedFiles}
                    setImgToParent={(files, imgs) => {
                      this.setState({
                        prevImgs: imgs,
                        importedFiles: files,
                      });
                    }}
                    rowIndex={i}></ImageImport>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

const ImageImport = ({
  prevImgs,
  importedFiles,
  rowIndex,
  setImgToParent,
  removeImg,
}) => {
  return (
    <td style={tdPhoto}>
      <div>
        {prevImgs[rowIndex][0] && (
          <img
            key={0}
            style={imgStyle}
            src={prevImgs[rowIndex][0]}
            alt='...'
            onClick={() => {
              removeImg(rowIndex, 0);
            }}
          />
        )}
        {prevImgs[rowIndex][1] && (
          <img
            key={1}
            style={imgStyle}
            src={prevImgs[rowIndex][1]}
            alt='...'
            onClick={() => {
              removeImg(rowIndex, 1);
            }}
          />
        )}
        {prevImgs[rowIndex][2] && (
          <img
            key={2}
            style={imgStyle}
            src={prevImgs[rowIndex][2]}
            alt='...'
            onClick={() => {
              removeImg(rowIndex, 2);
            }}
          />
        )}
      </div>
      <input
        style={{ color: 'transparent' }}
        type='file'
        onChange={(e) => {
          let files = importedFiles;
          let imgs = prevImgs;
          files[rowIndex] = e.target.files;
          let localImgs = [];
          for (let i = 0; i < e.target.files.length; i++) {
            localImgs.push(URL.createObjectURL(e.target.files[i]));
          }
          imgs[rowIndex] = localImgs;
          setImgToParent(files, imgs);
        }}
        multiple
      />
    </td>
  );
};

export default compose(
  graphql(signS3Mutation, { name: 'signS3' }),
  graphql(addReqCasesMutation, { name: 'addReqCases' }),
)(ReqCaseCreateForm);
