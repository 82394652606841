import React from 'react';
import {
  List,
  Datagrid,
  Edit,
  SimpleForm,
  TextField,
  ReferenceField,
  SelectField,
  Show,
  SimpleShowLayout,
  RichTextField,
  ArrayField,
  FormDataConsumer,
  SelectInput,
} from 'react-admin';
import queryString from 'query-string';
import { HosRespsListFilter } from '../common/filters';
import {
  OdocDateField,
  PresenceField,
  DirectUserReqField,
  ReqTypeField,
  ReqStateField,
  StringListField,
  GenderField,
  AgeGroupField,
  TherapyItemField,
  LocationStringField,
} from '../common/fields';
import { checkedAnsCallStateChoices } from '../common/choices';
import HosRespCreateForm from '../HosResp/HosRespCreateForm';

export const HosRespList = props => (
  <List
    {...props}
    filters={<HosRespsListFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    actions={null}
  >
    <Datagrid rowClick="show">
      <TextField label="아이디" source="id" />
      <ReferenceField label="상담 아이디" source="ureqId" reference="UserReq">
        <TextField source="id" />
      </ReferenceField>
      <DirectUserReqField label="상담구분" source="userRequest.directReq" />
      <ReferenceField
        sortable={false}
        label="답변병원"
        source="hospitalId"
        reference="Hospital"
        linkType="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        sortable={false}
        label="유저"
        source="user.id"
        reference="User"
      >
        <TextField source="username" />
      </ReferenceField>
      <TextField label="치과예상치료비(만원)" source="txMaxPrice" />
      <TextField label="교정예상치료비(만원)" source="orthoMaxPrice" />
      <TextField sortable={false} label="치료답변" source="treatmentAnswer" />
      <TextField sortable={false} label="증상답변" source="symptomAnswer" />
      <PresenceField sortable={false} label="조회여부" source="checkedAns" />
      <SelectField
        sortable={false}
        label="연락현황"
        source="checkedAns.callState"
        choices={checkedAnsCallStateChoices}
      />
      <OdocDateField
        sortable={false}
        label="답변조회일"
        source="checkedAns.createdAt"
      />
      <OdocDateField label="답변생성일" source="createdAt" />
    </Datagrid>
  </List>
);

export const HosRespShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="아이디" source="id" />
      <ReferenceField
        sortable={false}
        label="답변병원"
        source="hospitalId"
        reference="Hospital"
        linkType="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        sortable={false}
        label="유저"
        source="user.id"
        reference="User"
      >
        <TextField source="username" />
      </ReferenceField>
      <TextField label="치과예상치료비(만원)" source="txMaxPrice" />
      <TextField label="교정예상치료비(만원)" source="orthoMaxPrice" />
      <TextField sortable={false} label="치료답변" source="treatmentAnswer" />
      <TextField sortable={false} label="증상답변" source="symptomAnswer" />
      <PresenceField sortable={false} label="조회여부" source="checkedAns" />
      <SelectField
        sortable={false}
        label="연락현황"
        source="checkedAns.callState"
        choices={checkedAnsCallStateChoices}
      />
      <OdocDateField
        sortable={false}
        label="답변조회일"
        source="checkedAns.createdAt"
      />
      <OdocDateField label="답변생성일" source="createdAt" />

      <hr />
      {/* 상담 */}
      <ReferenceField label="상담 아이디" source="ureqId" reference="UserReq">
        <TextField source="id" />
      </ReferenceField>
      <DirectUserReqField label="상담구분" source="userRequest.directReq" />
      <ReqTypeField label="치료구분" source="userRequest.reqType" />
      <OdocDateField label="상담신청일" source="userRequest.createdAt" />
      <ReqStateField label="상담상태" source="userRequest.status" />
      <TextField
        label="티어"
        source="userRequest.chargeTier"
        sortable={false}
      />
      <StringListField label="고려사항" source="userRequest.considerTags" />
      <LocationStringField label="희망지역" source="userRequest.locations" />
      <RichTextField label="자세한상담" source="userRequest.desc" />
      <GenderField label="성별" source="userRequest.gender" />
      <AgeGroupField label="나이대" source="userRequest.ageGroup" />
      <ArrayField label="치과치료 항목" source="userRequest.txItems">
        <Datagrid>
          <TherapyItemField label="치료" source="itemcode" />
          <TextField label="갯수" source="unit" />
        </Datagrid>
      </ArrayField>
      <ArrayField label="교정치료 항목" source="userRequest.orthoItems">
        <Datagrid>
          <TherapyItemField label="치료" source="itemcode" />
          <TextField label="부분" source="part" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export const HosRespEdit = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm>
      <TextField label="아이디" source="id" />
      <ReferenceField
        sortable={false}
        label="답변병원"
        source="hospitalId"
        reference="Hospital"
        linkType="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        sortable={false}
        label="유저"
        source="user.id"
        reference="User"
      >
        <TextField source="username" />
      </ReferenceField>
      <TextField sortable={false} label="치료답변" source="treatmentAnswer" />
      <TextField sortable={false} label="증상답변" source="symptomAnswer" />
      <PresenceField sortable={false} label="조회여부" source="checkedAns" />
      <FormDataConsumer>
        {({ formData, dispatch, ...rest }) => {
          if (formData?.checkedAns?.callState) {
            return (
              <SelectInput
                label="연락현황"
                source="checkedAns.callState"
                choices={checkedAnsCallStateChoices}
              />
            );
          }
        }}
      </FormDataConsumer>
      <OdocDateField
        sortable={false}
        label="답변조회일"
        source="checkedAns.createdAt"
      />
      <OdocDateField label="답변생성일" source="createdAt" />
      <hr />
    </SimpleForm>
  </Edit>
);

export const HosRespCreate = ({ classes, location, ...props }) => {
  const params = queryString.parse(location.search);
  return (
    <HosRespCreateForm reqId={params.reqId} location={location} {...props} />
  );
};
