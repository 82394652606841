import React, { Component } from 'react'
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { FormDataConsumer, required } from 'ra-core';
import DiscountSettingCreateToolbar from '../DiscountSetting/DiscountSettingCreateToolbar';

const optionRenderer = ({ name, id }) => {
  return `${name}(${id})`;
};

const validateDiscountSettingCreation = values => {
  const errors = {};
  if (values.discountRatio > 100 || values.discountRatio < 0) {
    errors.discountRatio = '0~100사이의 양수만 가능합니다.';
  }

  return errors;
};

class DiscountSettingCreateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {},
    };
  }

  render() {
    return (
      <SimpleForm
        validate={(values) => {
          this.setState({ values });
          return validateDiscountSettingCreation(values);
        }}
        toolbar={<DiscountSettingCreateToolbar values={this.state.values} />}
      >
        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => {
            return (
              <React.Fragment>
                <TextInput label="할인율" source="discountRatio" validate={required()} />
                <ReferenceInput
                  perPage={15}
                  label="병원"
                  source="hospitalId"
                  reference="Hospital"
                  filterToQuery={searchText => ({ id: searchText })}
                  resettable
                  validate={required()}
                >
                  <AutocompleteInput optionText={optionRenderer} optionValue="id" />
                </ReferenceInput>
              </React.Fragment>
            )
          }}
        </FormDataConsumer>
      </SimpleForm>
    )
  }
}

export default DiscountSettingCreateForm;
