import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { compose, graphql, Query } from 'react-apollo';
import { getAlarmPhoneListQuery } from '../../graphql';
import { withRouter } from 'react-router-dom';

const tdStyle = {
  borderTop: 'solid 1px',
  padding: 1,
};

const AlarmList = (props) => {
  console.log(props)
  return (
    <table style={{ height: '100vh', width: 'auto', fontSize: 14 }}>
      <thead>
        <tr>
          <th>전화번호</th>
          <th>병원이름</th>
          <th>HUser</th>
          <th>HPeople</th>
        </tr>
      </thead>
      <tbody>
        <Query query={getAlarmPhoneListQuery}>
          {({ loading, error, data }) => {
            if (!loading && data && data.getAlarmPhoneListForAdmin) {
              const alarmList = data.getAlarmPhoneListForAdmin;
              return alarmList.map((e, i) => {
                return (
                  <tr key={i}>
                    <td style={tdStyle}>{e.recipientNo}</td>
                    <td style={tdStyle}>{e.hospitalName}</td>
                    <td style={tdStyle}>{e.huser}</td>
                    <td style={tdStyle}>{e.hpeople}</td>
                  </tr>
                );
              });
            }
            return null;
          }}
        </Query>
      </tbody>
    </table>
  );
};

export default withRouter(AlarmList);
