import React from 'react';
import { therapyInfoById } from '../../data/therapyInfo';

export const WantLevel = ({ wantLevel, ...rest }) => {
  switch (wantLevel) {
    case 1:
      return <span {...rest}>치료를 고민하는 중</span>;
    case 3:
      return <span {...rest}>치료의향을 느끼고 있음</span>;
    case 5:
      return <span {...rest}>최대한 빨리 치료를 원함</span>;
    default:
      return null;
  }
};

export const OrthoPart = ({ part, ...rest }) => {
  switch (part) {
    case 'AU':
      return <span {...rest}>윗니 전체</span>;
    case 'PU':
      return <span {...rest}>윗니 부분</span>;
    case 'AD':
      return <span {...rest}>아랫니 전체</span>;
    case 'PD':
      return <span {...rest}>아랫니 부분</span>;
    default:
      return null;
  }
};

export const TherapyItem = ({ itemcode, ...rest }) => (
  itemcode ? <span {...rest}>{therapyInfoById[itemcode].viewName}</span> : null
);

export default {};
