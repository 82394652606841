import React, { Component, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { readString } from 'react-papaparse';
import { addHospitalReviewsMutation } from '../../graphql';
import { graphql, compose } from 'react-apollo';
import moment from 'moment';

const MAX_ROW_COUNT = 30;

const thStyle = {
  minWidth: 80,
  maxWidth: 80,
  border: '1px solid grey',
  fontSize: 14,
};

const tdStyle = {
  minWidth: 80,
  maxWidth: 80,
  border: '1px solid grey',
  fontSize: 14,
};

const columnLength = 7;

const input = '1	작성자1	리뷰내용	FALSE	2020-06-01	리뷰출처	3';

class CreateReviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exampleRow: [],
      rows: [],
    };
  }

  componentDidMount = () => {
    const result = readString(input);
    console.log(result);
    if (result.data[0].length === columnLength) {
      this.setState({
        exampleRow: result.data[0],
      });
    }
  };

  // $hospitalId: ID!
  // $reviewerName: String!
  // $content: String!
  // $visible: Boolean
  // $reviewDate: Date
  // $referenceDescription: String
  // $rating: HospitalReviewRatingInput!

  parseBool = (value) => {
    return value.toLowerCase() === 'true';
  };
  submitReviews = async () => {
    const { rows } = this.state;
    try {
      if (!rows.length) {
        throw Error(`하나 이상의 리뷰가 입력되어야 합니다.`);
      }
      const data = rows.map((row, idx) => {
        const hospitalId = parseInt(row[0]);
        const reviewerName = row[1];
        const content = row[2];
        const visible = this.parseBool(row[3]);
        const reviewDate = row[4];
        const referenceDescription = row[5];
        const rating = { rating: parseInt(row[6]) };

        const date = moment(reviewDate, 'YYYY-MM-DD', true);
        if (!date.isValid())
          throw Error(`${idx + 1}열에 잘못된 날짜 정보가 입력되었습니다.`);

        // if (
        //   !ages.find((e) => {
        //     return e === userAgeGroup;
        //   })
        // )
        //   throw Error(`${idx + 1}열에 잘못된 나이 정보가 입력되었습니다.`);

        // if (
        //   !genders.find((e) => {
        //     return e === userGender;
        //   })
        // )
        //   throw Error(`${idx + 1}열에 잘못된 성별 정보가 입력되었습니다.`);

        return {
          hospitalId,
          reviewerName,
          content,
          visible,
          reviewDate,
          referenceDescription,
          rating,
        };
      });

      console.log(data);
      const result = await this.props.addHospitalReviews({
        variables: {
          data,
        },
      });
      console.log(result);
      alert('저장되었습니다.');
    } catch (error) {
      alert(`저장에 실패했습니다\n${error}`);
    }
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: '#FFFFFF',
          width: '100%',
          height: '100%',
          margin: 0,
          padding: 20,
        }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <textarea
            style={{
              width: 100,
              height: 32,
              padding: 0,
              margin: 0,
            }}
            value=''
            onChange={(e) => {
              if (
                this.state.rows.length > 0 &&
                !window.confirm(
                  '기존에 작성중이던 테이블이 있습니다. 정말 덮어쓰시겠습니까?',
                )
              ) {
                return;
              }
              const result = readString(e.target.value);

              if (result.data.length > MAX_ROW_COUNT) {
                alert(`최대 ${MAX_ROW_COUNT}열만 한번에 추가할 수 있습니다.`);
                return;
              }

              if (result.data[0].length === columnLength) {
                this.setState({
                  rows: result.data,
                });
              }
            }}></textarea>
          <button
            style={{
              width: 100,
              height: 32,
              padding: 0,
              margin: 0,
              marginLeft: 10,
            }}
            onClick={() => {
              if (
                this.state.rows.length > 0 &&
                !window.confirm(
                  '기존에 작성중이던 테이블이 있습니다. 정말 덮어쓰시겠습니까?',
                )
              ) {
                return;
              }

              this.setState({
                rows: [],
              });
            }}>
            초기화
          </button>
          <button
            disabled={this.state.uploading}
            style={{
              width: 100,
              height: 32,
              padding: 0,
              margin: 0,
              marginLeft: 10,
            }}
            onClick={this.submitReviews}>
            올리기
          </button>
        </div>
        <table
          style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
          <thead>
            <tr>
              {/* $hospitalId: ID! */}
              {/* $reviewerName: String! */}
              {/* $content: String! */}
              {/* $visible: Boolean */}
              {/* $reviewDate: Date */}
              {/* $referenceDescription: String */}
              {/* $rating: HospitalReviewRatingInput! */}
              <th style={thStyle}>병원 아이디*</th>
              <th style={thStyle}>리뷰 작성자*</th>
              <th style={thStyle}>내용*</th>
              <th style={thStyle}>공개여부</th>
              <th style={thStyle}>작성일*</th>
              <th style={thStyle}>출처</th>
              <th style={thStyle}>점수*</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ backgroundColor: 'grey', color: 'white' }}>
              {this.state.exampleRow.map((e, i) => {
                return (
                  <td key={i} style={tdStyle}>
                    {e}
                  </td>
                );
              })}
            </tr>
            {this.state.rows.map((e, i) => {
              return (
                <tr key={i}>
                  {e.map((e2, i2) => {
                    return (
                      <td key={i2} style={tdStyle}>
                        {e2}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default compose(
  graphql(addHospitalReviewsMutation, { name: 'addHospitalReviews' }),
)(withRouter(CreateReviews));
