import {
  allHusersQuery,
  allUsersQuery,
  allHospitalsQuery,
  allLogsQuery,
  allComplimentsQuery,
  addHospitalMutation,
  getHuserQuery,
  allUserReqsQuery,
  getUserQuery,
  allReservationsQuery,
  allHosRespsQuery,
  updateHospitalMutation,
  getHospitalQuery,
  getUserReqQuery,
  updateHuserMutation,
  getReservationQuery,
  allDoctorsQuery,
  getDoctorQuery,
  allUserReviewsQuery,
  getUserReviewQuery,
  updateUserReviewMutation,
  allAccountHistorysQuery,
  addAccountHistoryMutation,
  getHosRespQuery,
  updateHosRespMutation,
  allDiscountSettingsQuery,
  getDiscountSettingQuery,
  updateDiscountSettingMutation,
  addDiscountSettingMutaiton,
  addHosRespMutation,
  allHospitalReviewsQuery,
  getHospitalReviewQuery,
  allReqCasesQuery,
  getReqCaseQuery,
  addReqCasesMutation,
} from '.';

export default {
  Huser: {
    GET_LIST: allHusersQuery,
    GET_MANY: allHusersQuery,
    GET_ONE: getHuserQuery,
    UPDATE: updateHuserMutation,
  },
  User: {
    GET_LIST: allUsersQuery,
    GET_MANY: allUsersQuery,
    GET_ONE: getUserQuery,
  },
  Hospital: {
    GET_LIST: allHospitalsQuery,
    GET_MANY: allHospitalsQuery,
    GET_ONE: getHospitalQuery,
    CREATE: addHospitalMutation,
    UPDATE: updateHospitalMutation,
  },
  Log: {
    GET_LIST: allLogsQuery,
    GET_MANY: allLogsQuery,
  },
  Compliment: {
    GET_LIST: allComplimentsQuery,
    GET_MANY: allComplimentsQuery,
  },
  UserReq: {
    GET_LIST: allUserReqsQuery,
    GET_MANY: allUserReqsQuery,
    GET_ONE: getUserReqQuery,
  },
  HosResp: {
    GET_LIST: allHosRespsQuery,
    GET_MANY: allHosRespsQuery,
    GET_ONE: getHosRespQuery,
    UPDATE: updateHosRespMutation,
    CREATE: addHosRespMutation,
  },
  Reservation: {
    GET_LIST: allReservationsQuery,
    GET_MANY: allReservationsQuery,
    GET_ONE: getReservationQuery,
  },
  Doctor: {
    GET_LIST: allDoctorsQuery,
    GET_MANY: allDoctorsQuery,
    GET_ONE: getDoctorQuery,
  },
  UserReview: {
    GET_LIST: allUserReviewsQuery,
    GET_MANY: allUserReviewsQuery,
    GET_ONE: getUserReviewQuery,
    UPDATE: updateUserReviewMutation,
  },
  AccountHistory: {
    GET_LIST: allAccountHistorysQuery,
    CREATE: addAccountHistoryMutation,
  },
  DiscountSetting: {
    GET_LIST: allDiscountSettingsQuery,
    CREATE: addDiscountSettingMutaiton,
    GET_ONE: getDiscountSettingQuery,
    UPDATE: updateDiscountSettingMutation,
  },
  HospitalReview: {
    GET_LIST: allHospitalReviewsQuery,
    GET_MANY: allHospitalReviewsQuery,
    GET_ONE: getHospitalReviewQuery,
  },
  ReqCase: {
    GET_LIST: allReqCasesQuery,
    GET_MANY: allReqCasesQuery,
    GET_ONE: getReqCaseQuery,
    CREATE: addReqCasesMutation,
  },
};
