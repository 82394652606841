import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Edit,
  Show,
  TabbedShowLayout,
  Tab,
  ArrayField,
  ReferenceField,
  Create,
} from 'react-admin';
import { HospitalFilter } from '../common/filters';
import { TagsField, DoctorMetadataField, InfosField, HospitalPhotoField } from '../common/fields';
import HospitalCreateForm from '../Hospital/HospitalCreateForm';
import HospitalDutyHourField from '../Hospital/HospitalDutyHourField';
import HospitalEditForm from '../Hospital/HospitalEditForm';



export const HospitalList = props => (
  <List {...props} filters={<HospitalFilter />} sort={{ field: 'createdAt', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField label="아이디" source="id" />
      <TextField label="주소" source="address" />
      <TextField label="이름" source="name" />
      <TextField label="전화번호" source="phone" />
      <TextField label="위도" source="lat" />
      <TextField label="경도" source="lng" />
    </Datagrid>
  </List>
);

export const HospitalShow = props => (
  <Show {...props} filters={<HospitalFilter />} sort={{ field: 'createdAt', order: 'DESC' }}>
    <TabbedShowLayout>
      <Tab label="기본정보">
        <TextField label="아이디" source="id" />
        <TextField label="주소" source="address" />
        <TextField label="병원명" source="name" />
        <TextField label="전화번호" source="phone" />
        <TextField label="위치" source="locationString" />

        <TextField label="위도" source="lat" />
        <TextField label="경도" source="lng" />
      </Tab>
      <Tab label="담당자 정보">
        <ArrayField label="담당자" source="hospitalPeople">
          <Datagrid>
            <TextField label="이름" source="name" />
            <TextField
              label="연락처"
              source="phonenum"
            />
          </Datagrid>
        </ArrayField>
      </Tab>

      <Tab label="상세정보">
        <TextField label="한줄소개" source="metadata.intro" />
        <TextField label="오닥터 코멘트" source="metadata.onelineComment" />
        <div><b>진료시간</b></div>
        <HospitalDutyHourField
          label="월요일"
          source="metadata.openhour.dutyTime1"
        />
        <HospitalDutyHourField
          label="화요일"
          source="metadata.openhour.dutyTime2"
        />
        <HospitalDutyHourField
          label="수요일"
          source="metadata.openhour.dutyTime3"
        />
        <HospitalDutyHourField
          label="목요일"
          source="metadata.openhour.dutyTime4"
        />
        <HospitalDutyHourField
          label="금요일"
          source="metadata.openhour.dutyTime5"
        />
        <HospitalDutyHourField
          label="토요일"
          source="metadata.openhour.dutyTime6"
        />
        <HospitalDutyHourField
          label="일요일"
          source="metadata.openhour.dutyTime7"
        />
        <HospitalDutyHourField
          label="점심시간"
          source="metadata.openhour.lunchTime"
        />
        <TagsField label="특성" source="tags" />
        <InfosField label="추가정보" source="infos" />
      </Tab>
      <Tab label="사진">
        <HospitalPhotoField label="사진" source="photos" />
      </Tab>
      <Tab label="의사">
        <ArrayField label="의사" source="doctors">
          <Datagrid>
            <ReferenceField label="아이디" source="id" reference="Doctor" linkType="show">
              <TextField source="id" />
            </ReferenceField>
            <TextField label="이름" source="name" />
            <TextField label="전문분야" source="speciality" />
            <TextField label="소개" source="metadata.oneline_profile" />
            <DoctorMetadataField label="약력" source="metadata" />
          </Datagrid>
        </ArrayField>
      </Tab>
      {/* <Tab label="수가표"></Tab> */}
    </TabbedShowLayout>
  </Show>
);

export const HospitalCreate = props => (
  <Create {...props}><HospitalCreateForm /></Create>
);

export const HospitalEdit = props => (
  <Edit undoable={false} {...props}>
    <HospitalEditForm />
  </Edit>
);
