import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  SimpleShowLayout,
  Show,
  RichTextField,
  ArrayField,
} from 'react-admin';
import { UserFilter } from '../common/filters';
import { ReqStateField, GenderField, AgeGroupField, OdocDateField, StringListField } from '../common/fields';
import UserTerminateButton from '../User/UserTerminateButton';

export const UserList = props => (
  <List {...props} filters={<UserFilter />} sort={{ field: 'createdAt', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField label="아이디" source="id" />
      <TextField label="이름" source="username" />
      <TextField label="이메일" source="email" />
      <TextField label="연락처" source="phonenum" />
      <OdocDateField label="가입일" source="createdAt" />
    </Datagrid>
  </List>
);

export const UserShow = props => (
  <Show {...props} actions={<UserTerminateButton></UserTerminateButton>}>
    <SimpleShowLayout>
      <TextField label="아이디" source="id" />
      <TextField label="이름" source="username" />
      <TextField label="이메일" source="email" />
      <TextField label="연락처" source="phonenum" />
      <OdocDateField label="가입일" source="createdAt" />
      <ArrayField label="상담신청내역" source="userReqs">
        <Datagrid>
          <TextField label="아이디" source="id" />
          <TextField label="치료구분" source="reqType" />
          <OdocDateField label="상담신청일" source="createdAt" />
          <ReqStateField label="상담상태" source="status" />
          <StringListField label="고려사항" source="considerTags" />
          <RichTextField label="자세한상담" source="desc" />
          <GenderField label="성별" source="gender" />
          <AgeGroupField label="나이대" source="ageGroup" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);