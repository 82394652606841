import React from 'react';
import { Filter, TextInput, SelectInput, DateInput } from 'react-admin';
import {
  reviewStatusChoices,
  accountHistoryDirectionChoices,
  checkedAnsCallStateChoices,
} from './choices';

export const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label='이름' source='username' alwaysOn />
    <TextInput label='연락처' source='phonenum' alwaysOn />
    <TextInput label='이메일' source='email' alwaysOn />
  </Filter>
);

export const UserReqFilter = (props) => (
  <Filter {...props}>
    <TextInput label='이름' source='username' alwaysOn />
    <TextInput label='상담 아이디' source='id' alwaysOn />
  </Filter>
);

export const HospitalFilter = (props) => (
  <Filter {...props}>
    <TextInput label='병원이름' source='name' alwaysOn />
    <TextInput label='주소' source='address' alwaysOn />
    <TextInput label='전화번호' source='phone' alwaysOn />
  </Filter>
);

export const HospitalAndUserFilter = (props) => (
  <Filter {...props}>
    <TextInput label='병원이름' source='hosname' alwaysOn />
    <TextInput label='유저이름' source='username' alwaysOn />
    <SelectInput
      label='연락현황'
      source='callState'
      choices={checkedAnsCallStateChoices}
      alwaysOn
    />
  </Filter>
);

export const HosRespsListFilter = (props) => (
  <Filter {...props}>
    <TextInput label='답변아이디' source='id' alwaysOn />
    <TextInput label='병원이름' source='hosname' alwaysOn />
    <TextInput label='상담 아이디' source='reqId' alwaysOn />
    <TextInput label='유저이름' source='username' alwaysOn />
    <SelectInput
      label='연락현황'
      source='callState'
      choices={checkedAnsCallStateChoices}
      alwaysOn
    />
  </Filter>
);

export const DoctorFilter = (props) => (
  <Filter {...props}>
    <TextInput label='병원이름' source='hosname' alwaysOn />
    <TextInput label='의사이름' source='name' alwaysOn />
    <TextInput label='의사아이디' source='id' alwaysOn />
  </Filter>
);

export const UserReviewFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label='상태'
      source='status'
      choices={reviewStatusChoices}
      alwaysOn
    />
    <TextInput label='병원 아이디' source='hospitalId' alwaysOn />
    <TextInput label='의사 아이디' source='doctorId' alwaysOn />
  </Filter>
);

export const AccountHistoryFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label='구분'
      source='direction'
      choices={accountHistoryDirectionChoices}
      alwaysOn
    />
    <DateInput label='시작 날짜' source='startDate' alwaysOn />
    <DateInput label='종료 날짜' source='endDate' alwaysOn />
    <TextInput label='병원 아이디' source='hospitalId' alwaysOn />
    <TextInput label='답변 아이디' source='hosRespId' alwaysOn />
  </Filter>
);

export const DiscountSettingFilter = (props) => (
  <Filter {...props}>
    <TextInput label='병원이름' source='hosname' alwaysOn />
  </Filter>
);

export const HospitalReviewFilter = (props) => (
  <Filter {...props}>
    <TextInput label='병원 아이디' source='hospitalId' alwaysOn />
  </Filter>
);

export const ReqCaseFilter = (props) => (
  <Filter {...props}>
    <TextInput label='병원 아이디' source='hospitalId' alwaysOn />
    <TextInput label='치료 카테고리' source='therapyCategoryId' alwaysOn />
  </Filter>
);

export default {};
