import queryMap from './graphql/queryMap';

export default introspectionResults => (raFetchType, resourceName, params) => {
  if (!localStorage.getItem('odocAdminToken')) {
    return null;
  }

  switch (raFetchType) {
    case 'GET_LIST': {
      const variables = {
        filter: params.filter || {},
        sort: params.sort || {},
      };

      if (params.pagination) {
        variables.perPage = params.pagination.perPage;
        variables.currentPage = params.pagination.page;
      }

      return {
        query: queryMap[resourceName][raFetchType],
        variables,
        parseResponse: response => ({
          data: response.data[`all${resourceName}sForAdmin`].data || [],
          total:
            response.data[`all${resourceName}sForAdmin`].pagination.total ||
            0,
        }),
      };
    }
    case 'CREATE':
      return {
        query: queryMap[resourceName][raFetchType],
        variables: params.data,
        parseResponse: response => ({
          data: response.data[`add${resourceName}ForAdmin`],
        }),
      };
    case 'GET_ONE':
      return {
        query: queryMap[resourceName][raFetchType],
        variables: params,
        parseResponse: response => ({
          data: response.data[`get${resourceName}ForAdmin`],
        }),
      };
    case 'GET_MANY':
      return {
        query: queryMap[resourceName][raFetchType],
        variables: {
          filter: params,
        },
        parseResponse: response => ({
          data: response.data[`all${resourceName}sForAdmin`].data,
        }),
      };
    case 'UPDATE':
      if (params.data && params.data.id) delete params.data.id;
      if (params.data && params.data.__typename) delete params.data.__typename;
      return {
        query: queryMap[resourceName][raFetchType],
        variables: {
          id: params.id,
          data: params.data,
        },
        parseResponse: response => ({
          data: response.data[`update${resourceName}ForAdmin`],
        }),
      };
    default:
      return null;
  }
};
