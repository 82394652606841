import React, { Component } from 'react'
import {
  Toolbar,
} from 'react-admin';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import formatFilename from '../../utils/fileUpload';
import { graphql, compose } from 'react-apollo';
import { signS3Mutation, updateDoctorMutation } from '../../graphql';

class DoctorEditToolbar extends Component {

  uploadToS3 = async (file, signedRequest) => {
    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };
    const resp = await axios.put(signedRequest, file, options);
    return resp;
  };

  handleSubmit = async () => {
    const { values } = {...this.props};
 
    try {
      const { id, name, hospitalId, speciality, metadata, videos, photo } = values;

      if (!values || !id || !name || !hospitalId) {
        return;
      }
      
      let variables = {
        id,
        data: {
          name,
          hospitalId,
          speciality,
        },
      };

      // 사진 업로드
      if (photo) {
        // 가져온 사진이면 업로드 패스
        if (photo.url) {
          variables.data.photo = photo.url;
        } else {
          // s3에 사진 업로드 
          const res = await this.props.signS3({
            variables: {
              filename: formatFilename(photo.title),
              filetype: photo.rawFile.type,
            },
          });

          const { signedRequest, url } = res.data.signS3;

          try {
            await this.uploadToS3(photo.rawFile, signedRequest);
            variables.data.photo = url;
          } catch (e) {
            console.log('Image Upload Failed!');
          }
        }
      }

      // 동영상 프리뷰 업로드
      let videoList = [];
      if (videos && videos.length > 0) {
        videoList = [...videos];
        // s3에 사진 업로드
        await Promise.all(
          videos.map(async (video, index) => {
            if (video.thumbnail.url) {
              const { thumbnail, title, description, url } = video;
              videoList[index] = {
                thumbnail: thumbnail.url,
                title,
                description,
                url
              };
            } else {
              const res = await this.props.signS3({
                variables: {
                  filename: formatFilename(video.thumbnail.title),
                  filetype: video.thumbnail.rawFile.type,
                },
              });
    
              const { signedRequest, url } = res.data.signS3;
    
              try {
                await this.uploadToS3(video.thumbnail.rawFile, signedRequest);
                videoList[index] = {
                  thumbnail: url,
                  title: video.title,
                  description: video.description,
                  url: video.url
                };
              } catch (e) {
                console.log('Image Upload Failed!');
              }
            }
          }),
        );

        variables.data.videos = videoList;
      }

      if (metadata && (metadata.oneline_profile || (metadata.brief_history && metadata.brief_history.length > 0))) {
        variables.data.metadata = metadata;
      }

      const updateRes = await this.props.updateDoctor({ variables });
      this.props.history.push('/Doctor');
    } catch (e) {
      console.log(e);
    }

    
  }
  render() {
    return (
      <Toolbar {...this.props} >
        <button type="button" onClick={this.handleSubmit}>SAVE</button>
      </Toolbar>
    )
  }
}

export default compose(
  graphql(signS3Mutation, { name: 'signS3' }),
  graphql(updateDoctorMutation, { name: 'updateDoctor' }),
)(withRouter(DoctorEditToolbar));
