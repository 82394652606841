import React from 'react';
import _ from 'lodash';
import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  ReferenceField,
  Edit,
  SimpleForm,
  LongTextInput,
  ReferenceInput,
  AutocompleteInput,
  SelectField,
  SelectInput,
  CheckboxGroupInput,
  FunctionField,
} from 'react-admin';
import { UserReviewFilter } from '../common/filters';
import {
  OdocDateField,
  OdocReferenceField,
  ConditionalReferenceField,
} from '../common/fields';
import { reviewStatusChoices, reviewScoreChoices, reviewTherapyTypeChoices } from '../common/choices';

const ReviewDoctorField = (props) => {
  const { record } = props;
  if (record.doctor) {
    return (
      <OdocReferenceField
        {...props}
        idSource="id"
        nameSource="name"
        label="의사"
        source="doctor.id"
        reference="Doctor"
        linkType="show"
      />
    );
  }

  if (record.jsondata && record.jsondata.doctorName) {
    return (
      <div>{record.jsondata.doctorName}</div>
    );
  }

  return null;
}

export const UserReviewList = props => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} filters={<UserReviewFilter />}>
    <Datagrid rowClick="show">
      <TextField label="아이디" source="id" />
      <OdocReferenceField
        sortable={false}
        idSource="id"
        nameSource="name"
        label="병원"
        source="hospital.id"
        reference="Hospital"
        linkType="show"
      />
      <ReviewDoctorField
        sortable={false}
        label="의사"
        source="doctor.id"
      />
      <TextField label="만족도" source="score1" />
      <OdocReferenceField
        sortable={false}
        idSource="id"
        nameSource="username"
        label="유저"
        source="user.id"
        reference="User"
        linkType="show"
      />
      {/* <ConditionalReferenceField
        sortable={false}
        label="예약아이디"
        source="reservation.id"
        linkType="show"
        reference="Reservation"
      >
        <TextField source="id" />
      </ConditionalReferenceField> */}
      <SelectField label="상태" source="status" choices={reviewStatusChoices} />
      <OdocDateField label="리뷰등록일" source="createdAt" />
    </Datagrid>
  </List>
);

export const UserReviewShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="아이디" source="id" />
      <OdocDateField label="리뷰등록일" source="createdAt" />
      <OdocReferenceField
        idSource="id"
        nameSource="name"
        label="병원"
        source="hospital.id"
        reference="Hospital"
        linkType="show"
      />
      <ReviewDoctorField
        label="의사"
        source="doctor.id"
      />
      <FunctionField
        label="치료"
        render={(record) => record?.jsondata?.therapyType?.map(therapy => <span key={therapy}>{_.find(reviewTherapyTypeChoices, {id: therapy}).name}, </span>)}
      />
      <TextField label="만족도" source="score1" />
      <TextField label="치료" source="score2" />
      <TextField label="환경" source="score3" />
      <TextField label="소통" source="score4" />
      <TextField label="가치" source="score5" />
      <TextField label="내용" source="desc" />
      <OdocReferenceField
        idSource="id"
        nameSource="username"
        label="유저"
        source="user.id"
        reference="User"
        linkType="show"
      />
      {/* <ConditionalReferenceField
        label="예약아이디"
        source="reservation.id"
        linkType="show"
        reference="Reservation"
      >
        <TextField source="id" />
      </ConditionalReferenceField> */}
      <SelectField label="상태" source="status" choices={reviewStatusChoices} />
    </SimpleShowLayout>
  </Show>
);

const optionRenderer = ({ name, id }) => {
  return `${name}(${id})`;
};

export const UserReviewEdit = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm redirect="show">
      <TextField label="아이디" source="id" />
      <OdocDateField label="리뷰등록일" source="createdAt" />
      <ReferenceInput
        perPage={15}
        label="병원"
        source="hospital.id"
        reference="Hospital"
        filterToQuery={searchText => ({ id: searchText })}
        resettable
      >
        <AutocompleteInput optionText={optionRenderer} optionValue="id" />
      </ReferenceInput>
      {props.record && props.record.jsondata && props.record.jsondata.doctorName && (
        <TextField label="의사 이름" source="jsondata.doctorName" />
      )}
      <ReferenceInput
        perPage={15}
        label="의사"
        source="doctor.id"
        reference="Doctor"
        filterToQuery={searchText => ({ id: searchText })}
        allowEmpty
      >
        <AutocompleteInput optionText={optionRenderer} optionValue="id" translateChoice={false} />
      </ReferenceInput>
      <CheckboxGroupInput label="치료" source="jsondata.therapyType" choices={reviewTherapyTypeChoices} />
      <SelectInput
        label="만족도"
        source="score1"
        choices={reviewScoreChoices}
        translateChoice={false}
      />
      <SelectInput
        label="치료"
        source="score2"
        choices={reviewScoreChoices}
        translateChoice={false}
      />
      <SelectInput
        label="환경"
        source="score3"
        choices={reviewScoreChoices}
        translateChoice={false}
      />
      <SelectInput
        label="소통"
        source="score4"
        choices={reviewScoreChoices}
        translateChoice={false}
      />
      <SelectInput
        label="가치"
        source="score5"
        choices={reviewScoreChoices}
        translateChoice={false}
      />

      <LongTextInput label="내용" source="desc" />

      <ReferenceField
        label="유저"
        source="user.id"
        reference="User"
        linkType="show"
      >
        <TextField source="username" />
      </ReferenceField>
      {/* <ReferenceField
        label="예약아이디"
        source="reservation.id"
        linkType="show"
        reference="Reservation"
      >
        <TextField source="id" />
      </ReferenceField> */}
      <SelectInput label="상태" source="status" choices={reviewStatusChoices} />
    </SimpleForm>
  </Edit>
);

ReviewDoctorField.defaultProps = { addLabel: true };
