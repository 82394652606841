import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  ReferenceField,
  SimpleForm,
  SelectField,
  FunctionField,
  DateField,
  NumberField,
  ArrayField,
  ImageField,
  Create,
} from 'react-admin';
import { OdocReferenceField, LocationStringField } from '../common/fields';
import { ReqCaseFilter } from '../common/filters';
import ReqCaseCreateForm from '../ReqCase/ReqCaseCreateForm';

export const ReqCaseList = (props) => (
  <List
    {...props}
    sort={{ field: 'updatedAt', order: 'DESC' }}
    filters={<ReqCaseFilter />}>
    <Datagrid rowClick='show'>
      <TextField label='아이디' source='id' />
      <ArrayField label='답변' source='resps'>
        <Datagrid>
          <TextField label='상담ID' source='id' />
          {/* <ReferenceField label='상담 아이디' source='id' reference='UserReq'>
            <TextField source='id' />
          </ReferenceField> */}
          <OdocReferenceField
            sortable={false}
            idSource='id'
            nameSource='name'
            label='병원'
            source='hospital.id'
            reference='Hospital'
            linkType='show'
          />
        </Datagrid>
      </ArrayField>
      <ArrayField label='사진' source='casePhotos'>
        <Datagrid>
          <ImageField label='사진' source='url' />
        </Datagrid>
      </ArrayField>
      <ArrayField label='카테고리' source='categories'>
        <Datagrid>
          <TextField label='카테고리명' source='name' />
          <TextField label='카테고리설명' source='description' />
        </Datagrid>
      </ArrayField>
      <LocationStringField label='지역' source='location' sortable={false} />
      <TextField label='제목' source='title' />
      <TextField label='내용' source='content' />
      <TextField label='성별' source='userGender' />
      <TextField label='나이' source='userAgeGroup' />
      <DateField label='상담일' source='reqDate' />
    </Datagrid>
  </List>
);

export const ReqCaseShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label='아이디' source='id' />
      <ArrayField label='답변' source='resps'>
        <Datagrid>
          <TextField source='id' />
          <ReferenceField label='상담 아이디' source='id' reference='UserReq'>
            <TextField source='id' />
          </ReferenceField>{' '}
          <OdocReferenceField
            sortable={false}
            idSource='id'
            nameSource='name'
            label='병원'
            source='hospital.id'
            reference='Hospital'
            linkType='show'
          />
        </Datagrid>
      </ArrayField>
      <ArrayField label='사진' source='casePhotos'>
        <Datagrid>
          <ImageField label='사진' source='url' />
        </Datagrid>
      </ArrayField>
      <ArrayField label='카테고리' source='categories'>
        <Datagrid>
          <TextField label='카테고리명' source='name' />
          <TextField label='카테고리설명' source='description' />
        </Datagrid>
      </ArrayField>
      <LocationStringField label='지역' source='location' sortable={false} />
      <TextField label='제목' source='title' />
      <TextField label='내용' source='content' />
      <TextField label='성별' source='userGender' />
      <TextField label='나이' source='userAgeGroup' />
      <DateField label='상담일' source='reqDate' />
      <TextField label='생성' source='createdAt' />
      <TextField label='업데이트' source='updatedAt' />
    </SimpleShowLayout>
  </Show>
);

export const ReqCaseCreate = (props) => (
  <Create {...props}>
    <ReqCaseCreateForm {...props} />
  </Create>
);
