import React, { Component } from 'react'
import {
  ImageField,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  ImageInput,
  SimpleFormIterator,
  ArrayInput,
  LongTextInput,
} from 'react-admin';
import { FormDataConsumer, required } from 'ra-core';
import { StringArrayInput } from '../common/inputs';
import DoctorCreateToolbar from '../Doctor/DoctorCreateToolbar';

const optionRenderer = ({ name, id }) => {
  return `${name}(${id})`;
};

class DoctorCreateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {},
    };
  }

  render() {
    return (
      <SimpleForm
        validate={(values) => {
          this.setState({ values });
          return {};
        }}
        toolbar={<DoctorCreateToolbar values={this.state.values} />}
      >
        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => {
            return (
              <React.Fragment>
                <TextInput label="이름" source="name" validate={required()} />
                <ReferenceInput
                  perPage={15}
                  label="병원"
                  source="hospitalId"
                  reference="Hospital"
                  filterToQuery={searchText => ({ id: searchText })}
                  resettable
                  validate={required()}
                >
                  <AutocompleteInput optionText={optionRenderer} optionValue="id" />
                </ReferenceInput>
                <TextInput label="전문 분야" source="speciality" /><br />
                <LongTextInput label="소개" source="metadata.oneline_profile" />
                <StringArrayInput label="약력" source="metadata.brief_history" dispatch={dispatch} />
                <ImageInput label="사진" source="photo" accept="image/*">
                  <ImageField source="src" title="title" />
                </ImageInput>
                <ArrayInput label="동영상" source="videos">
                  <SimpleFormIterator>
                    <TextInput label="제목" source="title" />
                    <TextInput label="설명" source="description" />
                    <ImageInput label="썸네일" source="thumbnail" accept="image/*">
                      <ImageField source="src" title="title" />
                    </ImageInput>
                    <TextInput label="영상링크" source="url" />
                  </SimpleFormIterator>
                </ArrayInput>
              </React.Fragment>
            )
          }}
        </FormDataConsumer>
      </SimpleForm>      
    )
  }
}

export default DoctorCreateForm;
