import React from 'react';

import { change } from 'redux-form';
import {
  List,
  Datagrid,
  TextField,
  SelectField,
  NumberField,
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  NumberInput,
  FormDataConsumer,
  REDUX_FORM_NAME,
} from 'react-admin';
import {
  OdocReferenceField,
  OdocDateField,
  ConditionalReferenceField,
} from '../common/fields';
import {
  accountHistoryDirectionChoices,
  accountHistoryMethodFieldChoices,
  accountHistoryMethodInputChoices,
} from '../common/choices';
import { AccountHistoryFilter } from '../common/filters';
import { getVisibleAmountInput, hospitalOptionRenderer, HospitalCashInfo, CheckedAnsIdAutoCompleteInput, AccountHitoryAmountField, validateAccountHistoryCreate, RefundAccountFiatBtn } from '../AccountHistory/components';
import { isInteger } from '../common/validates';

export const AccountHistoryList = props => (
  <List
    title="충전금"
    {...props}
    filters={<AccountHistoryFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <Datagrid>
      <OdocReferenceField
        idSource="id"
        nameSource="name"
        label="병원"
        source="hospital.id"
        reference="Hospital"
        linkType="show"
      />
      <SelectField
        label="구분"
        source="direction"
        choices={accountHistoryDirectionChoices}
      />
      <SelectField label="내역" source="method" choices={accountHistoryMethodFieldChoices} />
      <AccountHitoryAmountField label="변동액" source="amount" />
      <NumberField locales="en-US" label="잔액" source="cumSum" />
      <ConditionalReferenceField
        label="상담 아이디"
        reference="UserReq"
        source="hosResps.userRequest.id"
      />
      <TextField
        label="답변 아이디"
        source="hosResps.id"
      />
      <OdocDateField label="일자" source="createdAt" />
      <RefundAccountFiatBtn props={props} />
    </Datagrid>
  </List>
);

export const AccountHistoryCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm validate={validateAccountHistoryCreate} redirect="show">
        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => {
            const methodChoices = accountHistoryMethodInputChoices.filter(
              method => method.direction === formData.direction,
            );

            const visibleJustAmountInput = getVisibleAmountInput(formData.direction, formData.method);
            return (
              <React.Fragment>
                <div>
                  {/* 병원 아이디 */}
                  <ReferenceInput
                    perPage={15}
                    label="병원 ID"
                    source="hospitalId"
                    reference="Hospital"
                    filterToQuery={searchText => isInteger(searchText) ? ({ id: searchText }) : null}
                    resettable
                    {...rest}
                    onChange={value => {
                      dispatch(change(REDUX_FORM_NAME, 'direction', null));
                      dispatch(change(REDUX_FORM_NAME, 'method', null));
                      dispatch(change(REDUX_FORM_NAME, 'caId', null));
                      dispatch(change(REDUX_FORM_NAME, 'amount', null));
                    }}
                  >
                    <AutocompleteInput
                      optionText={hospitalOptionRenderer}
                      optionValue="id"
                    />
                  </ReferenceInput>
                  {/* 병원 충전금 잔액 표시 */}
                  <HospitalCashInfo
                    hospitalId={formData.hospitalId}
                  />
                </div>
                {/* 충전 || 소진 구분 */}
                <SelectInput
                  label="구분"
                  source="direction"
                  choices={accountHistoryDirectionChoices}
                  onChange={value => {
                    dispatch(change(REDUX_FORM_NAME, 'method', null));
                    dispatch(change(REDUX_FORM_NAME, 'caId', null));
                    dispatch(change(REDUX_FORM_NAME, 'amount', null));
                  }}
                  {...rest}
                />

                {/* 충전 || 소진 내용 */}
                <SelectInput
                  label="내용"
                  source="method"
                  choices={methodChoices}
                  onChange={value => {
                    dispatch(change(REDUX_FORM_NAME, 'caId', null));
                    dispatch(change(REDUX_FORM_NAME, 'amount', null));
                  }}
                  {...rest}
                />
                {
                  formData.direction && formData.method && (
                    <div>
                      {/* 금액 */}
                      {visibleJustAmountInput && (
                        <NumberInput locales="en-US" label="금액" source="amount" {...rest} />
                      )}

                      {/* 답변 아이디 조회 */}
                      {!visibleJustAmountInput && (
                        <ReferenceInput
                          perPage={15}
                          label="답변 아이디"
                          source="caId"
                          reference="HosResp"
                          allowEmpty
                          filter={formData.hospitalId ? { hId: formData.hospitalId } : null}
                          filterToQuery={searchText => ({ id: searchText, hId: formData.hospitalId })}
                          onChange={value => {
                            dispatch(change(REDUX_FORM_NAME, 'amount', null));
                          }}
                          resettable
                          {...rest}
                        >
                          <CheckedAnsIdAutoCompleteInput />
                        </ReferenceInput>
                      )}


                      {/* 환불 금액 */}
                      {formData.caId && (
                        <NumberInput locales="en-US" label="환불 금액" source="amount" {...rest} />
                      )}
                    </div>
                  )
                }
              </React.Fragment>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default {};
