import React, { Component } from 'react'
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { FormDataConsumer, REDUX_FORM_NAME, required } from 'ra-core';
import DiscountSettingEditToolbar from './DiscountSettingEditToolbar';

const optionRenderer = ({ name, id }) => {
  return `${name}(${id})`;
};

class DiscountSettingEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {},
    };
  }

  validateDiscountSettingCreation = values => {
    const errors = {};
    if (values.discountRatio > 100 || values.discountRatio < 0) {
      errors.discountRatio = '0~100사이의 양수만 가능합니다.';
    }

    return errors;
  };

  render() {
    const { record, ...rest } = this.props;
    let newRecord = { ...record };
    if (record.hospital && record.hospital.id) {
      newRecord.hospitalId = record.hospital.id;
      delete newRecord.hospital;
    }

    return (
      <SimpleForm
        validate={(values) => {
          this.setState({ values });
          return this.validateDiscountSettingCreation(values);
        }}
        record={newRecord}
        toolbar={<DiscountSettingEditToolbar values={this.state.values} />}
        {...rest}
      >
        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => {
            return (
              <React.Fragment>
                <TextInput label="할인율" source="discountRatio" validate={required()} />
                <ReferenceInput
                  perPage={15}
                  label="병원"
                  source="hospitalId"
                  reference="Hospital"
                  filterToQuery={searchText => ({ name: searchText })}
                  resettable
                  validate={required()}
                >
                  <AutocompleteInput optionText={optionRenderer} optionValue="id" />
                </ReferenceInput>
              </React.Fragment>
            )
          }}
        </FormDataConsumer>
      </SimpleForm>
    )
  }
}

export default DiscountSettingEditForm;
