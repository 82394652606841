import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR } from 'react-admin';

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(`${process.env.REACT_APP_API_URL}/graphql`, {
      method: 'POST',
      body: JSON.stringify({
        operationNmae: null,
        query: `mutation ($id: String!, $password: String!) {
            loginAdmin(id: $id, password: $password) {
              ok
              token
              errors {
                path
                message
                __typename
              }
              __typename
            }
          }`,
        variables: {
          id: username,
          password,
        },
      }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ data }) => {
        localStorage.setItem('odocAdminToken', data.loginAdmin.token);
      });
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('odocAdminToken');
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem('odocAdminToken')
      ? Promise.resolve()
      : Promise.reject();
  }
  return Promise.reject('Unkown method');
};
