import React from 'react';
import { Query, Mutation } from 'react-apollo';
import _ from 'lodash';
import {
  getBalanceQuery,
  getHospitalQuery,
  refundAccountFiatMutation,
  allAccountHistorysQuery,
} from '../../graphql';
import { AutocompleteInput, NumberField } from 'react-admin';
import queryString from 'querystring';

export const hospitalOptionRenderer = ({ name, id }) => {
  return `${name}(${id})`;
};

export const hosRespsOptionRender = ({ id, userRequest, checkedAns }) => {
  if (checkedAns && checkedAns.accountFiat && checkedAns.accountFiat.amount) {
    return `${id}(${checkedAns.accountFiat.amount}원)`;
  }

  return `${id}(무료)`;
};

export const HospitalCashInfo = ({ hospitalId }) =>
  hospitalId ? (
    <div
      style={{
        width: '300px',
        padding: '10px',
        border: '1px solid rgba(0, 0, 0, 0.42)',
      }}
    >
      <div
        style={{
          display: 'inline-block',
        }}
      >
        <Query query={getHospitalQuery} variables={{ id: hospitalId }}>
          {({ data }) => {
            if (data && data.getHospitalForAdmin) {
              return <span>{data.getHospitalForAdmin.name}</span>;
            }
            return null;
          }}
        </Query>
      </div>
      <div
        style={{
          display: 'inline-block',
          marginLeft: '10px',
        }}
      >
        <span>
          <Query query={getBalanceQuery} variables={{ hospitalId }}>
            {({ data, loading }) => {
              if (data && data.getBalanceForAdmin) {
                return <span>{data.getBalanceForAdmin.balanceTotal} 원</span>;
              }
              return null;
            }}
          </Query>
        </span>
      </div>
    </div>
  ) : null;

export const CheckedAnsIdAutoCompleteInput = ({ choices, ...rest }) => {
  const newChoices = choices.filter(hosResp => hosResp.checkedAns);
  if (newChoices && newChoices.length > 0) {
    return (
      <AutocompleteInput
        optionText={hosRespsOptionRender}
        optionValue="checkedAns.id"
        choices={newChoices}
        {...rest}
      />
    );
  }
  return <div>환불 가능한 답변이 없습니다</div>;
};

export const getVisibleAmountInput = (direction, method) => {
  if (!direction || !method) {
    return false;
  }

  if (direction === 'in') {
    if (method === 'charge_account') {
      return true;
    }

    if (method === 'charge_free') {
      return true;
    }

    if (method === 'refund_etc') {
      return true;
    }
  }

  if (direction === 'out') {
    if (method === 'withdraw_etc') {
      return true;
    }
  }

  return false;
};

export const AccountHitoryAmountField = ({ record, source, ...rest }) => {
  const amount = _.get(record, source);
  const amountInt = parseInt(amount);

  if (amountInt > 0) {
    return <div>{`+${amountInt.toLocaleString()}`}</div>;
  }

  return (
    <NumberField locales="en-US" record={record} source={source} {...rest} />
  );
};

AccountHitoryAmountField.defaultProps = { addLabel: true };

export const validateAccountHistoryCreate = values => {
  const errors = {};

  if (!values.hospitalId) {
    errors.hospitalId = ['The hospitalId is required'];
  }

  if (!values.direction) {
    errors.direction = ['The direction is required'];
  }

  if (!values.method) {
    errors.method = ['The method is required'];
  }

  if (!values.amount) {
    errors.amount = ['The amount is required'];
  }

  return errors;
};


export const RefundAccountFiatBtn = ({ record, source, props }) => {
  const accountFiat = { ...record };
  const { hosResps } = accountFiat;

  // 상담 답변 과금 일 때, 답변 연락현황이 중복이나 결번일 때만 환불 가능
  if (
    accountFiat.direction === 'out' &&
    accountFiat.method === 'withdraw_counsel' &&
    hosResps &&
    hosResps.checkedAns &&
    (hosResps.checkedAns.callState === 'duplicated' ||
      hosResps.checkedAns.callState === 'invalidphone')
  ) {
    return (
      <Mutation
        mutation={refundAccountFiatMutation}
        variables={{
          id: accountFiat.id,
        }}
      >
        {(refundAccountFiat, { error, data }) => {
          const confirmRefund = () => {
            if (window.confirm("환불하시겠습니까?")) {
              refundAccountFiat();
            }
          }

          if (error) {
            alert('환불 할 수 없는 답변입니다.');
          }

          if (data) {
            window.location.reload();
          }

          return <button onClick={confirmRefund}>환불</button>;
        }}
      </Mutation>
    );
  }

  return null;
};
