import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
// import axios from 'axios';
import { graphql, compose } from 'react-apollo';
import { terminateUserForAdminMutation } from '../../graphql';

class UserTerminateButton extends Component {
  handleTerminate = async () => {
    const { data } = this.props;
    try {
      if (!data || !data.id) {
        return;
      }

      const ret = await this.props.terminateUser({
        variables: {
          id: data.id
        }
      });
      if (ret) {
        alert('유저가 탈퇴처리되었습니다.');
      }
      this.props.history.push('/User');
    } catch (e) {
      alert(e.message.replace('GraphQL error:', ''))
    }
  }
  render() {
    return (
      <button type="button" onClick={this.handleTerminate}>탈퇴</button>
    )
  }
}

export default compose(
  graphql(terminateUserForAdminMutation, { name: 'terminateUser' }),
)(withRouter(UserTerminateButton));
