import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  SelectField,
} from 'react-admin';
import { OdocDateField } from '../common/fields';

export const ComplimentList = props => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }}>
    <Datagrid>
      <TextField label="아이디" source="id" />
      <TextField sortable={false} label="내용" source="desc"/>
      <ReferenceField sortable={false} label="보낸사람" source="fromHuser.id" reference="Huser">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField sortable={false} label="보낸사람 소속병원" source="fromHuser.hospital.id" reference="Hospital" linkType="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="받은사람 번호" source="phonenum" />      
      <TextField label="받은사람 소속 병원" source="toHosName" />
      <TextField label="받은사람" source="toName" />
      <TextField sortable={false} label="받은사람(회원)" source="toHuser.username" />
      <SelectField label="칭찬 공개여부" source="status" choices={[
        { id: 'published', name: '공개' },
        { id: 'draft', name: '비공개' },
      ]} />
      <OdocDateField label="칭찬발신일" source="createdAt" />
    </Datagrid>
  </List>
);

export default {};
