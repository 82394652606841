import React, { Component } from 'react';
import { compose, graphql, Query } from 'react-apollo';
import styled from 'styled-components';
import _ from 'lodash';
import ImageGallery from 'react-image-gallery';
import {
  Create,
  SimpleForm,
  required,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  Toolbar,
  SaveButton,
} from 'react-admin';
import { Button } from '@material-ui/core';
import {
  getUserReqQuery,
  allHospitalsQuery,
  getHospitalQuery,
  addHosRespMutation,
} from '../../graphql';
import {
  WantLevel,
  OrthoPart,
  TherapyItem,
} from '../UserReq/UserReqDataComponents';
import { Age, Gender } from '../User/UserDataComponents';
import ArrowImg from '../../images/ic-more-black-24.svg';

const getLocationString = (locations) => {
  let locationString = '';
  if (locations && locations.length > 0) {
    locations.forEach((e, i) => {
      locationString += `${e.siName} ${e.gunguName}`;
      if (i !== locations.length - 1) {
        locationString += ' / ';
      }
    });
  }

  return locationString;
};

const ReqViewContainer = styled.div`
  width: 100%;
  font-size: 16px;
  table {
    width: 100%;
    border: 0;
    tr > td {
      border: 0;
      padding: 5px 2px;
      &:first-of-type {
        width: 120px;
        font-weight: bold;
        /* backgrou nd-color: rgba(0, 0, 0, 0.2); */
      }
      input,
      textarea {
        font-size: 16px;
        padding: 6px 10px;
      }
      input.number-input {
        text-align: right;
        width: 84px;
        height: 32px;
        border: solid 1px #d6d6d6;
        background-color: #ffffff;
        box-sizing: border-box;
        vertical-align: middle;
      }
    }
    .td-option {
      width: 100px;
    }
  }
  .total-price {
    text-align: right;
    font-size: 23px;
    font-weight: bold;
    padding: 7px 0;
  }
  select {
    &,
    &:focus,
    &:active {
      margin-left: 8px;
      vertical-align: middle;
      width: 84px;
      height: 32px;
      border: solid 1px #d6d6d6;
      background-color: #ffffff;
      padding: 6px 10px;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #000000;
      border-radius: 0;
      vertical-align: middle;
      outline: none;
      background-color: #ffffff;
      background-image: url(${ArrowImg});
      background-position-x: 100%;
      background-position-y: 50%;
      background-repeat: no-repeat;
      background-size: 24px 24px;
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      ::-ms-expand {
        display: none;
      }
    }
  }
`;

const HosRespToolbar = props => (
  <Toolbar {...props}>
    <SaveButton
      label="post.action.save_and_show"
      redirect="show"
      submitOnEnter={true}
    />
  </Toolbar>
);

class HosRespCreateForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hospitalId: '',
      treatmentAnswer: '',
      symptomAnswer: '',
      txMaxPrice: 0,
      orthoMaxPrice: 0,
      txPriceTable: [],
      orthoPriceTable: [],
      treatPeriod: '',
      treatPeriodTag: 'months',
    };
  }

  componentWillReceiveProps(props) {
    const { req } = props;
    if (!req?.loading && req?.getUserReqForAdmin) {
      // priceTable 만들기
      const { txItems, orthoItems } = req?.getUserReqForAdmin;
      const txPriceTable = txItems.reduce(
        (arr, item) => [
          ...arr,
          {
            itemcode: item.itemcode,
            unit: item.unit,
            price: 0,
          },
        ],
        [],
      );
      const orthoPriceTable = orthoItems.reduce(
        (arr, item) => [
          ...arr,
          {
            itemcode: item.itemcode,
            part: item.part,
            price: 0,
          },
        ],
        [],
      );

      this.setState({
        txPriceTable,
        orthoPriceTable,
      });
    }
  }

  calcTxPriceTotal = txPriceTable =>
    txPriceTable?.reduce(
      (total, data) => (data.price !== '' ? (total += data.price) : total),
      0,
    );

  calcOrthoPriceTotal = orthoPriceTable =>
    orthoPriceTable?.reduce(
      (total, data) => (data.price !== '' ? (total += data.price) : total),
      0,
    );

  handleTxPriceChange = ({ target }) => {
    const { name, value } = target;
    const { txPriceTable } = this.state;
    const re = /^[0-9\b]+$/;

    if (value === '' || re.test(value)) {
      const price = value === '' ? value : parseInt(value);
      const newTxPriceTable = [...txPriceTable];
      const idx = _.findIndex(newTxPriceTable, o => o.itemcode === name);
      newTxPriceTable[idx] = {
        ...newTxPriceTable[idx],
        price,
      };

      this.setState({
        txPriceTable: newTxPriceTable,
        txMaxPrice: this.calcTxPriceTotal(newTxPriceTable),
      });
    }
  };

  handleOrthoPriceChange = (e, itemcode, part) => {
    const { value, name } = e.target;
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      const price = value === '' ? value : parseInt(value);

      const newOrthoPriceTable = [...this.state.orthoPriceTable];
      const idx = _.findIndex(
        newOrthoPriceTable,
        o => o.itemcode === name && o.part === part,
      );
      newOrthoPriceTable[idx] = {
        ...newOrthoPriceTable[idx],
        price,
      };

      this.setState({
        orthoPriceTable: newOrthoPriceTable,
        orthoMaxPrice: this.calcOrthoPriceTotal(newOrthoPriceTable),
      });
    }
  };

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  handleAnswerChange = e => {
    const { name, value } = e.target;
    const newState = { ...this.state };
    _.set(newState, name, value);
    this.setState(newState);
  };

  handleSubmit = async () => {
    const {
      hospitalId,
      txMaxPrice,
      orthoMaxPrice,
      treatmentAnswer,
      symptomAnswer,
      orthoPriceTable,
      txPriceTable,
      treatPeriod,
      treatPeriodTag,
    } = this.state;

    const {
      req,
      reqId,
      toast,
      openAlert,
      closeAlert,
      history,
      updateHosResps,
      submitHosResps,
    } = this.props;

    const { loading, getUserReqForAdmin } = req;
    if (loading || !getUserReqForAdmin) {
      return null;
    }

    const { reqType } = getUserReqForAdmin;

    // validation check start
    if (!hospitalId) {
      alert('병원 아이디를 작성해주세요');
      return;
    }

    if (!treatmentAnswer) {
      alert('치료 답변을 작성해주세요');
      return;
    }

    if (
      (reqType === 'all' &&
        (_.find(txPriceTable, { price: '' }) ||
          _.find(orthoPriceTable, { price: '' }))) ||
      (reqType === 'tx' && _.find(txPriceTable, { price: '' })) ||
      (reqType === 'ortho' && _.find(orthoPriceTable, { price: '' }))
    ) {
      alert('예상 치료비와 답변 내용을 작성해주세요');
      return;
    }

    // variable making
    const variables = {
      ureqId: reqId,
      hospitalId,
    };

    if (txMaxPrice > 0) {
      variables.txMaxPrice = txMaxPrice;
    }

    if (orthoMaxPrice > 0) {
      variables.orthoMaxPrice = orthoMaxPrice;
    }

    if (txMaxPrice > 0 && txPriceTable.length > 0) {
      variables.txPrices = [];
      await txPriceTable.forEach(data => {
        if (data.price !== '') {
          variables.txPrices.push({
            itemcode: data.itemcode,
            price: data.price,
          });
        }
      });
    }

    if (orthoMaxPrice > 0 && orthoPriceTable.length > 0) {
      variables.orthoPrices = [];
      await orthoPriceTable.forEach(data => {
        if (data.price !== '') {
          variables.orthoPrices.push({
            itemcode: data.itemcode,
            part: data.part,
            price: data.price,
          });
        }
      });
    }

    if (treatmentAnswer && treatmentAnswer.length > 0) {
      variables.treatmentAnswer = treatmentAnswer;
    }

    if (symptomAnswer && symptomAnswer.length > 0) {
      variables.symptomAnswer = symptomAnswer;
    }

    if (treatPeriod) {
      variables.metadata = {
        treatPeriod,
        treatPeriodTag,
      };
    }

    // mutation
    try {
      let id = null;
      // 답변 생성
      const res = await submitHosResps({
        variables,
      });

      id = res.data.addHosRespForAdmin.id;

      if (id) {
        alert('등록완료');
        history.push(`UserReq/${reqId}/show`);
        return;
      }
    } catch (e) {
      // 현재 답변 submit 잔액부족하면 alert and 충전신청페이지로 redirect
      if (e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message) {
        if (e.graphQLErrors[0].message === '충전이 필요합니다.') {
          alert('충전이 필요합니다.');
          return;
        }

        alert(e.graphQLErrors[0].message);
      }
    }
  };

  onChangeHospital = ({ target }) => {
    this.setState({ hospitalId: target.value });
  };

  render() {
    const { req } = this.props;
    const {
      hospitalId,
      txPriceTable,
      txMaxPrice,
      orthoPriceTable,
      orthoMaxPrice,
      treatPeriod,
      treatPeriodTag,
      treatmentAnswer,
      symptomAnswer
    } = this.state;
    const { loading, getUserReqForAdmin } = req;
    if (loading || !getUserReqForAdmin) {
      return null;
    }

    const request = getUserReqForAdmin;
    const images = request.txImageUrls.concat(request.orthoImageUrls).reduce((photos, url) => (
      [
        ...photos,
        {original: url}
      ]
    ), []);;
    return (
      <Create {...this.props}>
        {/* <SimpleForm toolbar={<HosRespToolbar handleSubmit={this.handleSubmit} />}> */}
        <SimpleForm toolbar={null}>
          <ReqViewContainer style={{ width: '100%' }}>
            <h1>답변등록</h1>
            <div>
              <h2>답변할 병원 아이디</h2>
              <div>
                {/* <FormDataConsumer>
                  {({ formData, dispatch, ...rest }) => {
                    
                    return (
                      <ReferenceInput
                        perPage={15}
                        label="병원 ID"
                        source="hospitalId"
                        reference="Hospital"
                        filterToQuery={searchText => ({ id: searchText })}
                        resettable
                        validate={required()}
                        onChange={() => this.setState({ hospitalId: formData })}
                      >
                        <AutocompleteInput
                          optionText={({ name, id }) => `${name}(${id})`}
                          optionValue="id"
                        />
                      </ReferenceInput>
                    );
                  }}
                </FormDataConsumer> */}
                <input
                  type="text"
                  name="hospitalId"
                  onChange={this.onChangeHospital}
                  placeholder="병원아이디"
                  autoComplete="off"
                />
                {hospitalId && (
                  <Query
                    query={getHospitalQuery}
                    variables={{ id: hospitalId }}
                    skip={!!!hospitalId}
                    fetchPolicy="network-only"
                  >
                    {({ loading, data, error, refetch, ...rest }) => {
                      if (loading) {
                        data = null;
                        error = null;
                        return <span>&nbsp;&nbsp;&nbsp; loading...</span>;
                      }

                      if (error) {
                        return (
                          <span>
                            &nbsp;&nbsp;&nbsp; => 존재하지 않는 병원입니다.
                          </span>
                        );
                      }

                      if (!loading && data?.getHospitalForAdmin) {
                        return (
                          <span>
                            &nbsp;&nbsp;&nbsp; =>{' '}
                            {data?.getHospitalForAdmin?.name}
                            {data?.getHospitalForAdmin?.id}
                          </span>
                        );
                      }

                      return null;
                    }}
                  </Query>
                )}
              </div>
              <h2>유저정보</h2>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        {request?.user?.username}({request?.user.id}) |{' '}
                        {request?.locations && getLocationString(request.locations)} |{' '}
                        <Age age={request?.ageGroup} /> |{' '}
                        <Gender gender={request?.gender} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr />
              <h2>상담정보</h2>
              <div>
                {images?.length > 0 && (
                  <ImageGallery
                    showThumbnails={false}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    items={images}
                    renderItem={item => <img src={item.original} style={{ width: '100%', height: '320px', objectFit: 'contain' }} />}
                  />
                )}
                <table>
                  <tbody>
                    <tr>
                      <td>희망개선부분</td>
                      <td>{request.orthoWanna.map(wanna => wanna + ' | ')}</td>
                    </tr>
                    <tr>
                      <td>문의내용</td>
                      <td>{request.desc}</td>
                    </tr>
                    <tr>
                      <td>치료의향</td>
                      <td>
                        <WantLevel wantLevel={request.wantLevel} />
                      </td>
                    </tr>
                    <tr>
                      <td>상담시고려사항</td>
                      <td>{request.considerTags.map(tag => tag + ' | ')}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr />
              <h2>상담답변</h2>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>수가</td>
                      <td>
                        <table>
                          <tbody>
                            {txPriceTable?.map(tx => (
                              <tr key={`tx-table-${tx.itemcode}`}>
                                <td>
                                  <TherapyItem itemcode={tx.itemcode} />
                                </td>
                                <td className="td-option">{tx.unit} 개(회)</td>
                                <td>
                                  <input
                                    type="text"
                                    name={tx.itemcode}
                                    value={tx.price}
                                    className="number-input"
                                    onChange={this.handleTxPriceChange}
                                  />{' '}
                                  만원
                                </td>
                              </tr>
                            ))}
                            {orthoPriceTable?.map(ortho => (
                              <tr key={`ortho-table-${ortho.itemcode}`}>
                                <td>
                                  <TherapyItem itemcode={ortho.itemcode} />
                                </td>
                                <td className="td-option">
                                  <OrthoPart part={ortho.part} />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name={ortho.itemcode}
                                    value={ortho.price}
                                    className="number-input"
                                    onChange={e =>
                                      this.handleOrthoPriceChange(
                                        e,
                                        ortho.itemcode,
                                        ortho.part,
                                      )
                                    }
                                  />{' '}
                                  만원
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr>
                      <td>치료기간</td>
                      <td colSpan="2">
                        <input
                          name="treatPeriod"
                          value={treatPeriod}
                          className="number-input"
                          onChange={this.handleChange}
                        />
                        <select
                          name="treatPeriodTag"
                          value={treatPeriodTag}
                          onChange={this.handleChange}
                        >
                          <option value="months">개월</option>
                          <option value="weeks">주</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>치료답변</td>
                      <td colSpan="2">
                        <textarea
                          style={{ width: '80%', minHeight: '450px' }}
                          placeholder="치료 답변 내용"
                          name="treatmentAnswer"
                          value={treatmentAnswer}
                          onChange={this.handleAnswerChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>증상답변</td>
                      <td colSpan="2">
                        <textarea
                          style={{ width: '80%', minHeight: '450px' }}
                          placeholder="증상 답변 내용"
                          name="symptomAnswer"
                          value={symptomAnswer}
                          onChange={this.handleAnswerChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                {txPriceTable?.length > 0 && (
                  <div className="total-price">
                    일반치료 합계: <span>{txMaxPrice} 만원</span>
                  </div>
                )}
                {orthoPriceTable?.length > 0 && (
                  <div className="total-price">
                    교정치료 합계: <span>{orthoMaxPrice} 만원</span>
                  </div>
                )}
              </div>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={this.handleSubmit}
              >
                저장
              </Button>
            </div>
          </ReqViewContainer>
        </SimpleForm>
      </Create>
    );
  }
}

export default compose(
  graphql(getUserReqQuery, {
    name: 'req',
    options: ({ reqId }) => ({
      variables: { id: reqId },
    }),
    skip: ({reqId}) => !reqId
  }),
  graphql(addHosRespMutation, {
    name: 'submitHosResps',
  }),
)(HosRespCreateForm);
