import React, { Component } from 'react'
import {
  ImageField,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  ImageInput,
  SimpleFormIterator,
  ArrayInput,
  LongTextInput,
} from 'react-admin';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { FormDataConsumer, REDUX_FORM_NAME, required } from 'ra-core';
import { StringArrayInput } from '../common/inputs';
import DoctorCreateToolbar from '../Doctor/DoctorCreateToolbar';
import DoctorEditToolbar from './DoctorEditToolbar';

const optionRenderer = ({ name, id }) => {
  return `${name}(${id})`;
};

class DoctorEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {},
    };
  }


  validateHospitalCreation = values => {
    const errors = {};
    if (values.tags && values.tags.length > 3) {
      errors.tags = '3개까지 선택 가능합니다';
    }

    if (values.infos && values.infos.length > 3) {
      errors.infos = '3개까지 선택 가능합니다';
    }
    return errors;
  };

  render() {
    const { record, ...rest } = this.props;
    let newRecord = {...record};
    if (record.hospital && record.hospital.id) {
      newRecord.hospitalId = record.hospital.id;
      delete newRecord.hospital;
    }

    if (record.photos && record.photos[0]) {
      newRecord.photo = {
        src: record.photos[0].url,
        url: record.photos[0].url
      };
      delete newRecord.photos;
    }

    if (record.videos && record.videos.length > 0) {
      let newVideos = [...record.videos];
      record.videos.map(({ title, description, url, thumbnail }, index) => {
        newVideos[index] = {
          title,
          description,
          url,
          thumbnail: {
            src: thumbnail,
            url: thumbnail,
          }
        };
      })
      newRecord.videos = newVideos;
    }

    return (
      <SimpleForm
        validate={(values) => {
          this.setState({ values });
          return this.validateHospitalCreation(values);
        }}
        record={newRecord}
        toolbar={<DoctorEditToolbar values={this.state.values} />}
        {...rest}
      >
        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => {
            return (
              <React.Fragment>
                <TextInput label="이름" source="name" validate={required()} />
                <ReferenceInput
                  perPage={15}
                  label="병원"
                  source="hospitalId"
                  reference="Hospital"
                  filterToQuery={searchText => ({ name: searchText })}
                  resettable
                  validate={required()}
                >
                  <AutocompleteInput optionText={optionRenderer} optionValue="id" />
                </ReferenceInput>
                <TextInput label="전문 분야" source="speciality" /><br />
                <LongTextInput label="소개" source="metadata.oneline_profile" />
                <StringArrayInput label="약력" source="metadata.brief_history" dispatch={dispatch} record={formData} />
                <ImageInput label="사진" source="photo" accept="image/*">
                  <ImageField source="src" title="title" />
                </ImageInput>
                <ArrayInput label="동영상" source="videos">
                  <SimpleFormIterator>
                    <TextInput label="제목" source="title" />
                    <TextInput label="설명" source="description" />
                    <ImageInput label="썸네일" source="thumbnail" accept="image/*">
                      <ImageField source="src" title="title" />
                    </ImageInput>
                    <TextInput label="영상링크" source="url" />
                  </SimpleFormIterator>
                </ArrayInput>
              </React.Fragment>
            )
          }}
        </FormDataConsumer>
      </SimpleForm>      
    )
  }
}

export default DoctorEditForm;
